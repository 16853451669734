import axios from 'axios';
import config from '../config/config.json';

import { UUID, encryption } from './common';
import { logger } from '../services/commonFunction';
//get the search data
export async function service(reqbody, url, apiURL) {
  try {
    var body = {
      reqbody: reqbody,
      apiname: url,
    };
    let resp = '';

    // implement the new initiate-search result api -----start------
    const apiList = [
      'ssrPriceRecheck',
      'redirectToItenaryPage',
      'reviewFlight',
      'priceReCheck',
    ];
    logger('API URL ::', config.apiEncryption);
    if (apiURL) {
      if (apiList.indexOf(url) > -1) {
        const newbody = {
          reqBody: reqbody,
          apiName: url,
        };
        logger(
          `API URL trueeeee====> ${config.glideURL}${apiURL}`,
          JSON.stringify(newbody)
        );
        resp = await axios
          .post(`${config.glideURL}${apiURL}`, { params: newbody })
          .then((response) => {
            logger(
              `API Response for ${url}:`,
              JSON.stringify(response.data)
            );
          })
          .catch((error) => {
            console.error('Error making API request:', error);
          });
      } else {
        logger(
          `API URL ====> , ${config.glideURL}${apiURL}, request body ====> ${reqbody}`
        );
        resp = await axios
          .post(`${config.glideURL}${apiURL}`, reqbody, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            logger('API Response for initiate-search :', response.data);
          })
          .catch((error) => {
            console.error('Error making API request:', error);
          });
      }
    }
    if (config.apiEncryption) {
      var encryptedString = encryption(JSON.stringify(body));
      // logger(encryptedString);

      resp = await axios.post(
        '/flightsapi/' + UUID(),
        { params: encryptedString },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
    } else {
      resp = await axios
        .post(`/flightsapi/${url}`, { params: body })
        .catch((err) => logger(err));
    }

    // implement the new initiate-search result api -----end------

    // let Error = {
    //     errorDescription: resp.data?.msg || resp.data?.data?.msg || resp.message || resp.data.message || '',
    //     errorCode: resp?.statuscode || resp.data.statuscode ||''
    // }
    // if( Error.errorCode != 200 ){
    //     if(resp.data?.status != 200 && !resp.data?.length){
    //         logger('in',resp.data?.status != 200 , resp.status != 200);
    //     Adobe.errorTracking(Error)
    // }
    // }
    return resp.data;
  } catch (error) {
    logger(error);
    return {};
  }
}

const returnEncryptedURL = (apiURL, enableEncrption) => {
  return apiURL;
  if(enableEncrption) {
    let apiURLGlide = apiURL.split('/');
    apiURL = `/${apiURLGlide[1]}/${encryption(apiURLGlide[2]).encryptedData}`;
    return apiURL;
  } else {
    return apiURL;
  }
}

export async function newService(reqbody, url, apiURL) {
  try {
    // implement the new initiate-search result api -----start-----
    if (url !== 'getBanners') {
      apiURL = returnEncryptedURL(apiURL, config.apiEncryption);
    }
    let res = {},
      newbody = {};

    const apiList = [
      'ssrPriceRecheck',
      'redirectToItenaryPage',
      'reviewFlight',
      'priceReCheck',
      'ssrInfo',
      'countryTeleCodeList',
      'getswmData',
      'searchFilter',
      'verifyVoucher',
      'getMemberBenefitDetails',
      'createOrder',
      'getRzpErrorMsg',
      'verifyBookingStatus',
      'getBookingData',
      'sendEmailSms',
      'getBookingDetails',
      'bookingFailedService',
      'verifyBookingDetails',
      'getBookingCancellationData',
      'bookingCancellation'
    ];
    if (apiList.indexOf(url) > -1) {
      if (url === 'redirectToItenaryPage' || url === 'getRzpErrorMsg') {
        newbody = reqbody;
      } else {
        newbody = {
          reqBody: reqbody,
          apiName: url,
        };
      }
      let reqParam ;
      if (config.apiEncryption) {
        reqParam =
          url === "redirectToItenaryPage" || url === "getRzpErrorMsg"
            ? JSON.stringify(encryption(JSON.stringify(newbody)))
            : JSON.stringify(encryption(JSON.stringify({ params: newbody })));
        logger(
          `API URL with Encrypted Data====> ${config.glideURL}${apiURL}`,
          JSON.stringify(reqParam)
        );
      } else {
        reqParam = (url === "redirectToItenaryPage" || url === "getRzpErrorMsg")
            ? newbody
            : { params: newbody };
      }
      res = await axios
        .post(
          `${config.glideURL}${apiURL}`,
          reqParam,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
        )
        .then((response) => {
          logger(
            `API Response for ${url}:`,
            JSON.stringify(response.data)
          );
          return response.data;
        })
        .catch((error) => {
          console.error('Error making API request:', error);
          return {};
        });
    } else if (url === 'fetchImage') {
        res = await axios
        .get(`${config.glideURL}/glide/fetchImage?image=${reqbody}`)
        .then((response) => {
          logger(
            `API Response for ${url}:`,
            JSON.stringify(response.data)
          );
          return response.data;
        })
        .catch((error) => {
          console.error('Error making API request:', error);
          return {};
        }); 
    } else if (url === 'getBookingVoucher' || url === 'getTaxInvoice' ) {
      let reqParam ;
      if (config.apiEncryption) {
        reqParam = JSON.stringify(encryption((reqbody)))
      } else {
        reqParam = reqbody;
      }
      const orderID = reqParam.split('?');
      res = await axios({
        url: `${config.glideURL}${apiURL}?orderId=${encodeURIComponent(
          orderID[0]
        )}`, //your url
        method: "GET",
        // responseType: 'blob', // important
      })
        .then((response) => {
          logger(`API Response for ${url}:`, JSON.stringify(response));
          return response;
        })
        .catch((error) => {
          console.error("Error making API request:", error);
          return {};
        }); 
    } else if (url === 'getStaffMiles') {
      res = await axios({
        url: `${config.glideURL}${apiURL}?imNumber=${encodeURIComponent(reqbody)}`, //your url
        method: 'GET',
        })  
      .then((response) => {
          logger(
            `API Response for ${url}:`,
            JSON.stringify(response)
          );
          return response.data;
        })
        .catch((error) => {
          console.error('Error making API request:', error);
          return {};
        }); 
    } else {
      if (url === 'searchEarnFlights') {
        logger(
          `API URL ====> ${
            config.glideURL
          }${apiURL}, request body ====> ${JSON.stringify(reqbody)}`
        );
        let reqParam ;
        if (config.apiEncryption) {
          reqParam =
              JSON.stringify(encryption(JSON.stringify(reqbody)));
          logger(
            `API URL with Encrypted Data====> ${config.glideURL}${apiURL}`,
            JSON.stringify(reqParam)
          );
        } else {
          reqParam = reqbody;
        }
  
        res = await axios
          .post(`${config.glideURL}${apiURL}`, reqParam, {
            headers: {
              'Content-Type': 'application/json',
            },
          })
          .then((response) => {
            logger('API Response :', response.data);
            return response.data;
          })
          .catch((error) => {
            console.error('Error making API request:', error);
            return {};
          });
      } else {
        let newbody = {
          reqBody: reqbody,
          apiName: url,
        };
        let reqParam;
        if (config.apiEncryption && url !== 'getBanners') {
          reqParam =
            url === autosuggest
              ? JSON.stringify(encryption(JSON.stringify(reqbody)))
              : JSON.stringify(encryption(JSON.stringify({ params: newbody })));
          logger(
            `API URL with Encrypted Data====> ${config.glideURL}${apiURL}`,
            JSON.stringify(reqParam)
          );
        } else {
          reqParam = url === autosuggest ? reqbody : { params: newbody };
        }
          res = await axios
          .post(
            `${config.glideURL}${apiURL}`,
            reqParam,
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          )
          .then((response) => {
            logger('API Response :', response.data);
            return response.data;
          })
          .catch((error) => {
            console.error('Error making API request:', error);
            return {};
          });
      }
      // implement the new initiate-search result api -----end------
    }
    return res;
  } catch (error) {
    logger('Jumping on error block !!!');
    logger(error);
    return {};
  }
}

export const autosuggest = 'autosuggest';
