import { IMAuthSDK } from '@jetprivilege/im-auth-sdk';
import config from '../config/config.json';
import { getCookieValue1, setSessionId } from './common';
import { logger } from '../services/commonFunction';
let auth0Config = {
    "domain": config.auth0.domain,
    "clientID": config.auth0.clientID,
    "redirectUri": "",
    "responseType": config.auth0.responseType,
    "scope": config.auth0.scope,
    "audience": config.auth0.audience
}
const keyCloakConfig = {
    "url": config.keyCloak.url,
    "realm": config.keyCloak.realm,
    "clientId": config.keyCloak.clientId
}


export async function checkKeyCloakSession() {
    // const auth0Config = {
    //     "domain": config.auth0.domain,
    //     "clientID": config.auth0.clientID,
    //     "redirectUri": window.location.origin + config.auth0.callbackURL,
    //     "responseType": config.auth0.responseType,
    //     "scope": config.auth0.scope,
    //     "audience": config.auth0.audience
    // }
    // const keyCloakConfig = {
    //     "url": config.keyCloak.url,
    //     "realm": config.keyCloak.realm,
    //     "clientId": config.keyCloak.clientId
    // }

    auth0Config.redirectUri = window.location.origin + config.auth0.callbackURL;

    const webauth0 = new IMAuthSDK(keyCloakConfig, auth0Config, config.keyCloak.environment);
    if (!getCookieValue1('JSESSION', 'jsessionid')) {
        await setSessionId(window)
    }
    return callCheckSession(webauth0, keyCloakConfig, auth0Config);

    // return new Promise((resolve, reject) => {
    //     // logger("Inside Auth Service ===========================");  
    //     let token = null;
    //     let userDetailsInfo = {};
    //     webauth0.checkSession(keyCloakConfig,auth0Config).then(authResult => {
    //             logger("====== User Auth Data ======", authResult);
    //             window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '';
    //             if (authResult && authResult.accessToken && authResult.idToken) {
    //                 token = authResult.idToken
    //                 let namespace = "https://www.intermiles.com";
    //                 let NameSpaceIdentifier = "https://www.intermiles.com" + "/";
    //                 let OIDC = "";
    //                 let pointsBalance = "";
    //                 // logger("====== Auth Result idTokenPayload Before =======", authResult.idTokenPayload.user_id);
    //                 if (authResult.idTokenPayload[namespace + "/OIDC"] != undefined &&
    //                     authResult.idTokenPayload[namespace + "/OIDC"] === "ON") {
    //                     let idTokenPayload = JSON.stringify(authResult.idTokenPayload);
    //                     let regex = new RegExp(NameSpaceIdentifier, "g");
    //                     authResult.idTokenPayload = JSON.parse(idTokenPayload.replace(regex, ""));
    //                     OIDC = "ON";
    //                     pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalance;
    //                     authResult.idTokenPayload.cris_Member_PointsBalanace = authResult.idTokenPayload.cris_Member_PointsBalance;
    //                 } else {
    //                 OIDC = "OFF";
    //                 pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalanace;
    //                 }
    //                 // logger("====== Auth Result idTokenPayload After =======", authResult.idTokenPayload.user_id);

    //                 userDetailsInfo = authResult.idTokenPayload;
    //                 //isLoggedIn = true;
    //                 window['UD'] =
    //                 {
    //                 "JPNumber": authResult.idTokenPayload.user_id.replace("auth0|00", ""),
    //                 "name": authResult.idTokenPayload.cris_Member_FirstName,
    //                 "PointsBalanace": pointsBalance,
    //                 "tier": authResult.idTokenPayload.cris_Member_Tier,
    //                 "EmailVerified": authResult.idTokenPayload.cris_Member_IsEmailVerified,
    //                 "MobileVerified": authResult.idTokenPayload.cris_Member_IsMobileVerified,
    //                 "PersonId": authResult.idTokenPayload.cris_Member_PersonID,
    //                 "activeStatus": authResult.idTokenPayload.cris_Member_ActiveStatus,
    //                 "age": "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
    //                 "cityofResidence": "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
    //                 "countryofResidence": "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
    //                 "gender": "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
    //                 "firstloginDate": authResult.idTokenPayload.created_at,
    //                 "lastloginDate": authResult.idTokenPayload.cris_Member_LastLoginDate,
    //                 "memProgram": authResult.idTokenPayload.cris_Member_MemProgram,
    //                 "LoggedInUsing": "JPNO"
    //                 };
    //                 window['JPNumber'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
    //                 window['JPNo'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
    //                 window['OIDC'] = OIDC;
    //                 localStorage.setItem("fs", authResult.idTokenPayload.created_at);
    //                 localStorage.setItem("ls", authResult.idTokenPayload.cris_Member_LastLoginDate);

    //                 let loggedIn_Info = {};
    //                 if (userDetailsInfo && userDetailsInfo.nickname) {
    //                     loggedIn_Info = {
    //                         member_number: userDetailsInfo.nickname,
    //                         tier: userDetailsInfo["cris_Member_Tier"],
    //                         isLoggedIn: true,
    //                         email: userDetailsInfo["cris_Member_EmailAddress"],
    //                         first_name: userDetailsInfo["cris_Member_FirstName"],
    //                         last_name: userDetailsInfo["cris_Member_LastName"],
    //                         mobile_number: userDetailsInfo["cris_Member_MobileNumber"],
    //                         member_title: userDetailsInfo["cris_Member_Title"],
    //                         points_balance: userDetailsInfo["cris_Member_PointsBalance"],
    //                         gender: userDetailsInfo["cris_Member_Gender"],
    //                         country: userDetailsInfo["cris_Member_CountryOfResidence"],
    //                         at_hash: userDetailsInfo["at_hash"] || '',
    //                         PID: userDetailsInfo["cris_Member_PersonID"],
    //                         token: token
    //                       }
    //                 } else {
    //                     loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
    //                 }

    //                 resolve(loggedIn_Info);
    //             } else {
    //                 userDetailsInfo = undefined;
    //                 window["MvpHeader"].auth0LogoutMS1();
    //                 sessionStorage.removeItem("membername");
    //                 sessionStorage.removeItem("jpnumber");
    //                 let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
    //                 resolve(loggedIn_Info)
    //             }
    //         })
    // })
}

export function loginToggleModal() {
    const webauth0Login = new IMAuthSDK(keyCloakConfig, auth0Config, config.keyCloak.environment);
    webauth0Login.loginToggleModal();
}

function callCheckSession(webauth0, keyCloakConfig, auth0Config) {
    return new Promise((resolve, reject) => {
        // logger("Inside Auth Service ===========================");  
        let token = null;
        let userDetailsInfo = {};
        let silentCheckSSO = {}

        if(!config.env_prod && window.location.origin == config.appUrl){
            silentCheckSSO = {
                silentCheckSsoRedirectUri: config.appUrl + "/static/keycloak/silent-check-sso.html"
            }
        }
        
        webauth0.checkSession(silentCheckSSO,auth0Config).then(authResult => {
            logger("====== User Auth Data ======", authResult);
            window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '';
            if (authResult && authResult.accessToken && authResult.idToken) {
                token = authResult.idToken
                let namespace = "https://www.intermiles.com";
                let NameSpaceIdentifier = "https://www.intermiles.com" + "/";
                let OIDC = "";
                let pointsBalance = "";
                // logger("====== Auth Result idTokenPayload Before =======", authResult.idTokenPayload.user_id);
                if (authResult.idTokenPayload[namespace + "/OIDC"] != undefined &&
                    authResult.idTokenPayload[namespace + "/OIDC"] === "ON") {
                    let idTokenPayload = JSON.stringify(authResult.idTokenPayload);
                    let regex = new RegExp(NameSpaceIdentifier, "g");
                    authResult.idTokenPayload = JSON.parse(idTokenPayload.replace(regex, ""));
                    OIDC = "ON";
                    pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalance;
                    authResult.idTokenPayload.cris_Member_PointsBalanace = authResult.idTokenPayload.cris_Member_PointsBalance;
                } else {
                    OIDC = "OFF";
                    pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalanace;
                }
                // logger("====== Auth Result idTokenPayload After =======", authResult.idTokenPayload.user_id);

                userDetailsInfo = authResult.idTokenPayload;
                //isLoggedIn = true;
                window['UD'] =
                {
                    "JPNumber": authResult.idTokenPayload.user_id.replace("auth0|00", ""),
                    "name": authResult.idTokenPayload.cris_Member_FirstName,
                    "PointsBalanace": pointsBalance,
                    "tier": authResult.idTokenPayload.cris_Member_Tier,
                    "EmailVerified": authResult.idTokenPayload.cris_Member_IsEmailVerified,
                    "MobileVerified": authResult.idTokenPayload.cris_Member_IsMobileVerified,
                    "PersonId": authResult.idTokenPayload.cris_Member_PersonID,
                    "activeStatus": authResult.idTokenPayload.cris_Member_ActiveStatus,
                    "age": "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    "cityofResidence": "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
                    "countryofResidence": "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
                    "gender": "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
                    "firstloginDate": authResult.idTokenPayload.created_at,
                    "lastloginDate": authResult.idTokenPayload.cris_Member_LastLoginDate,
                    "memProgram": authResult.idTokenPayload.cris_Member_MemProgram,
                    "LoggedInUsing": "JPNO"
                };
                window['JPNumber'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
                window['JPNo'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
                window['OIDC'] = OIDC;
                localStorage.setItem("fs", authResult.idTokenPayload.created_at);
                localStorage.setItem("ls", authResult.idTokenPayload.cris_Member_LastLoginDate);

                let loggedIn_Info = {};
                if (userDetailsInfo && userDetailsInfo.nickname) {
                    loggedIn_Info = {
                        member_number: userDetailsInfo.nickname,
                        tier: userDetailsInfo["cris_Member_Tier"],
                        isLoggedIn: true,
                        email: userDetailsInfo["cris_Member_EmailAddress"],
                        first_name: userDetailsInfo["cris_Member_FirstName"],
                        last_name: userDetailsInfo["cris_Member_LastName"],
                        mobile_number: userDetailsInfo["cris_Member_MobileNumber"],
                        member_title: userDetailsInfo["cris_Member_Title"],
                        points_balance: userDetailsInfo["cris_Member_PointsBalance"],
                        gender: userDetailsInfo["cris_Member_Gender"],
                        country: userDetailsInfo["cris_Member_CountryOfResidence"],
                        at_hash: userDetailsInfo["at_hash"] || '',
                        PID: userDetailsInfo["cris_Member_PersonID"],
                        token: token
                    }
                } else {
                    loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
                }

                resolve(loggedIn_Info);
            } else {
                userDetailsInfo = undefined;
                webauth0.auth0LogoutMS1();
                sessionStorage.removeItem("membername");
                sessionStorage.removeItem("jpnumber");
                let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
                resolve(loggedIn_Info)
            }
        }).catch(error => {
            logger("error>>>", error);
            logger("window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '';", window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '')
            window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '';
            userDetailsInfo = undefined;
            webauth0.auth0LogoutMS1();
            sessionStorage.removeItem("membername");
            sessionStorage.removeItem("jpnumber");
            let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
            resolve(loggedIn_Info)
        })
    })
}