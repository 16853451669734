import crypto from 'crypto';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import config from '../config/config.json';
var cipherKey =
  '6251655468576D5A7134743777217A25';
var iv = crypto.randomBytes(16);
import Cookies from 'js-cookie';
import { Adobe } from '../actions/adobeLayer';
import { newService } from '../actions/middleware';
import { logger } from '../services/commonFunction';
export function UUID() {
  return uuidv4();
}

export const encryption = function (text) {
  const cipher = crypto.createCipheriv('aes-256-cbc', cipherKey, iv);
  let encrypted = cipher.update(text, 'utf8', 'base64');
  encrypted += cipher.final('base64');
  // let cipher = crypto.createCipheriv(
  //   algorithm,
  //   Buffer.from(str2ab(cipherKey)),
  //   iv
  // );
  // let encrypted = cipher.update(text);
  // encrypted = Buffer.concat([encrypted, cipher.final()]);
  return  { iv: iv.toString('base64'), encryptedData: encrypted };
};

export const decryption = function (text) {
  let iv2 = Buffer.from(text.iv, 'hex');
  let encryptedText = Buffer.from(text.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv(
    algorithm,
    Buffer.from(str2ab(cipherKey)),
    iv2
  );
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
};

function str2ab(str) {
  var buf = new ArrayBuffer(str.length / 2); // 2 bytes for each char
  var bufView = new Uint16Array(buf);
  for (var i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}

// export function encryption(text) {
//     try { // logger(text);
//         var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
//         var cipher = crypto.createCipheriv(algorithm,  Buffer.from(cipherKey, 'hex'),iv);
//         // var encrypted = cipher.update(text, 'utf8', 'hex') + cipher.final('hex');
//         return cipher;
//     } catch (err) {
//         logger(err);
//     }
// }
// export function decryption(encrypted) {
//     try {
//         var algorithm = 'aes256'; // or any other algorithm supported by OpenSSL
//         var decipher = crypto.createDecipheriv(algorithm,  Buffer.from(cipherKey, 'hex'),iv);
//         // var decrypted = decipher.update(encrypted, 'hex', 'utf8') + decipher.final('utf8');
//         return(decipher);
//     } catch (err) {
//         logger(err);
//     }
// }
export function loadRazorPay() {
  const script = document.createElement('script');
  script.src = config.razorpay_script;
  script.async = true;
  document.body.appendChild(script);
}
export function doPaymentUBA(data, object, status, isCobrands) {
  let callBackURL = config.glideRedirectionURL;
  // config.redirectionURL +
  // (!!isCobrands && isCobrands == 'true'
  //   ? 'verifyRzpRespCOBAUBA'
  //   : 'verifyRzpRespUBA');
  logger(
    'callback URL ==============================================',
    JSON.stringify(callBackURL)
  );
  var options = {
    key: data.rzp.key,
    amount: data.rzp.amt,
    currency: 'INR',
    name: config.razorPay.payment_gateway_name,
    description: config.razorPay.payment_gateway_descriptions,
    order_id: data.rzp.oid,
    callback_url: callBackURL,
    redirect: true,
    email: '',
    contact: '',
    prefill: {
      email: !status ? object.emailID : '',
      contact: !status ? object.mobileNo : '',
      country_code: 91,
    },
    save: 1,
    notes: {
      transaction_id: data.rzp.refNo,
    },
    theme: {},
    modal: {},
  };

  if (
    config.UPIPaymentRzp.isFeatureActive &&
    object.orderInfo.voucherNo == config.UPIPaymentRzp.upiPromocode
  ) {
    options.config = {
      display: {
        blocks: {
          banks: {
            name: 'Pay via UPI',
            instruments: [
              {
                method: 'upi',
              },
            ],
          },
        },
        sequence: ['block.banks'],
        preferences: {
          show_default_blocks: !config.UPIPaymentRzp.showOnlyUPIPayment,
        },
      },
    };
  }
  logger('Razorpay options passed == >>> ,', JSON.stringify(options));
  // logger(options)
  var rzp1 = new window.Razorpay(options);
  // logger("Razorpay rzpl output == ")
  // logger(rzp1)
  sessionStorage.setItem('first_booking', 'true');
  rzp1.open();
}
export function doPaymentUBR(data, object) {
  var options = {
    key: data.rzp.key,
    amount: data.rzp.amt,
    currency: 'INR',
    name: config.razorPay.payment_gateway_name,
    description: config.razorPay.payment_gateway_descriptions_ubr,
    order_id: data.rzp.oid,
    callback_url: config.redirectionURL + 'verifyRzpRespUBR',
    redirect: true,
    email: '',
    contact: '',
    prefill: {
      email: object.email,
      contact: object.mobile,
      country_code: 91,
    },
    save: 1,
    notes: {
      transaction_id: data.rzp.refNo,
    },
    theme: {},
    modal: {},
  };
  // logger("Razorpay options passed == >>>")
  // logger(options)
  var rzp1 = new window.Razorpay(options);
  // logger("Razorpay rzpl output == ")
  // logger(rzp1)
  sessionStorage.setItem('first_booking', 'true');
  rzp1.open();
}
// export function razorPaymentStatusCheck(razor_payment_id, key, secretkey) {
//     try {
//         return new Promise(function (resolve, reject) {

//             let rzp = new Razorpay({
//                 key_id: key, // your `KEY_ID`
//                 key_secret: secretkey // your `KEY_SECRET`
//             })

//             rzp.payments.fetch(razor_payment_id).then((data) => { // success
//                 resolve(data)
//             }).catch((error) => { // failure
//                 resolve(error)
//             })
//         })
//     } catch (error) {
//         logger("error-===->", error);
//     }
// }
// export function razorOrderStatusCheck(razor_order_id, key, secretkey) {
//     try {
//         return new Promise(function (resolve, reject) {
//             let rzp = new Razorpay({
//                 key_id: key, // your `KEY_ID`
//                 key_secret: secretkey // your `KEY_SECRET`
//             })

//             rzp.orders.fetch(razor_order_id).then((data) => { // success
//                 resolve(data)
//             }).catch((error) => { // failure
//                 resolve(error)
//             })
//         })
//     } catch (error) {
//         logger("error-->", error);
//     }
// }
export function content() {
  return 'application/json';
}
export function authorization() {
  return config.authorization;
}
export function intializeOneDirect(loggedIn_Info, key, windowObject) {
  if (!!loggedIn_Info.isLoggedIn) {
    let data = loggedIn_Info;
    if (key != '') {
      let profileDetailMap = {};
      let defaultAttributeMap = {};
      profileDetailMap = {
        im_number: data.member_number,
        tier: data.tier,
        phone_number: data.mobile_number,
        environment: config.onedirectSurvey.environment,
      };

      defaultAttributeMap = {
        fullName: data.first_name + ' ' + data.last_name,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        gender: data.gender,
        country: data.country,
        customer_hash: data.at_hash,
      };
      windowObject.odSdkInitialize(
        null,
        profileDetailMap,
        null,
        defaultAttributeMap
      );
    }
  } else {
    if (key != '') {
      let profileDetailMap = {};
      profileDetailMap = {
        environment: config.onedirectSurvey.environment,
      };
      windowObject.odSdkInitialize(null, profileDetailMap, null, null);
    }
  }
}
// export function userLoggedInfo() {
//     logger("Global User Obect:==>", global.window.UD);
//     if (!!global.window.UD) {
//         return {
//             member_number: global.window.UD.JPNumber,
//             tier: global.window.UD.JPNumber,
//             isLoggedIn: true,
//             email: global.window.UD.EmailId,
//             first_name: global.window.UD.FirstName,
//             last_name: global.window.UD.LastName,
//             mobile_number: global.window.UD.MobileNo,
//             member_title: global.window.UD.Title,
//             points_balance: global.window.UD.PointsBalanace,
//             PID: global.window.UD.PersonId
//           }
//     } else {
//         return { member_number: 0, tier: "", isLoggedIn: false }
//     }
// }

export async function setSessionId(windowObject) {
  // let jsessionid = this.uuidv4();
  return new Promise((resolve, reject) => {
    let jsessionid = '';
    let finalReturnValue = '';
    var sessiontime = localStorageUtils.getItem('sessiont');
    // logger("sessiontime", sessiontime);
    var expirytime = getcurrenttime();
    if (windowObject.navigator.userAgent.indexOf('.NET') != -1) {
      if (sessiontime != '' && sessiontime != null && sessiontime != 'null') {
        // logger("sessiontime", sessiontime, "expirytime", expirytime);
        let validttime = moment
          .utc(moment(expirytime, 'mm').diff(moment(sessiontime, 'mm')))
          .format('mm');
        // logger("validttime", validttime, typeof Number(validttime));
        if (Number(validttime) < 10) {
          // logger("valid");
          jsessionid = localStorageUtils.getItem('sessionurl');
        } else {
          // logger("INvalid");
          jsessionid = uuidv4_ie();
        }
      } else {
        jsessionid = uuidv4_ie();
      }
    } else {
      // //logger("chrome--mozilla-safari");
      if (sessiontime != '' && sessiontime != null && sessiontime != 'null') {
        // logger("sessiontime", sessiontime, "expirytime", expirytime);
        let validttime = moment
          .utc(moment(expirytime, 'mm').diff(moment(sessiontime, 'mm')))
          .format('mm');
        // logger("validttime", validttime, typeof Number(validttime));
        if (Number(validttime) < 10) {
          // logger("valid");
          jsessionid = localStorageUtils.getItem('sessionurl');
        } else {
          // logger("INvalid");
          jsessionid = uuidv4();
        }
      } else {
        jsessionid = uuidv4();
      }
    }
    let d = new Date();
    d.setTime(d.getTime() + 2 * 24 * 60 * 60 * 1000);
    let ctime = 'expires=' + d.toUTCString();
    let sessid = 'jsessionid=' + jsessionid + '&jsessiontime=' + new Date();
    let sessid1 = jsessionid;
    document.cookie =
      'JSESSION=' + encodeURIComponent(sessid) + '; ' + ctime + '; path=/';
    // logger("JSESSION", Cookies.get('SESSION_ID'));
    if (Cookies.get('SESSION_ID') != undefined) {
      // logger("JSESSION1", Cookies.get("SESSION_ID"));
      finalReturnValue = Cookies.get('SESSION_ID');
    } else {
      // logger("JSESSION2", Cookies.get("SESSION_ID"), "sessid1", sessid1);
      document.cookie = 'SESSION_ID=' + encodeURIComponent(sessid1);
      finalReturnValue = encodeURIComponent(sessid1);
      localStorageUtils.setItem('sessiont', getcurrenttime());
      localStorageUtils.setItem('sessionurl', Cookies.get('SESSION_ID'));
      // logger("JSESSION3", Cookies.get("SESSION_ID"));
    }
    resolve(finalReturnValue);
  });
}

function getcurrenttime() {
  var current_time = moment().format('mm');
  return current_time;
}

function uuidv4_ie() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

export function getUTMParams() {
  let params = new URLSearchParams(window.location.search),
    keys = [
      'utm_source',
      'utm_campaign',
      'utm_medium',
      'utm_content',
      'utm_marketing_channel',
    ],
    utm_params_str = keys
      .reduce((a, k) => {
        return a + (params.get(k) ? `${k}=${params.get(k)}&` : '');
      }, '?')
      .slice(0, -1);
  if (utm_params_str != '')
    sessionStorage.setItem('utm_params', utm_params_str);
  return utm_params_str;
}

export function loadJSDynamic(url) {
  return new Promise((resp, rej) => {
    var e = document.createElement('script');
    e.src = url;
    e.type = 'text/javascript';
    document.getElementsByTagName('head')[0].appendChild(e);
    resp(true);
  });
}

export const localStorageUtils = {
  getItem: (key) => {
    try {
      // logger("common localStorageGetItem key: ", key)
      return localStorage.getItem(key);
    } catch (e) {
      logger('common localStorageGetItem error:', e.message);
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      logger('localStorageSetItem error:', e.message);
    }
  },
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      logger('localStorageRemoveItem error:', e.message);
    }
  },
};
export const sessionStorageUtils = {
  getItem: (key) => {
    try {
      return sessionStorage.getItem(key);
    } catch (e) {
      logger('sessionStorageGetItem error:', e.message);
      return null;
    }
  },
  setItem: (key, value) => {
    try {
      sessionStorage.setItem(key, value);
    } catch (e) {
      logger('sessionStorageSetItem error:', e.message);
    }
  },
  removeItem: (key) => {
    try {
      sessionStorage.removeItem(key);
    } catch (e) {
      logger('sessionStorageremoveItem error:', e.message);
    }
  },
};
export function clearSessionStorage() {
  let userdata = JSON.parse(sessionStorage.getItem('userdata'));
  let travellerinfo = JSON.parse(sessionStorage.getItem('travellerinfo'));
  let addsOn = JSON.parse(sessionStorage.getItem('addsOn'));
  let IMTravelling = JSON.parse(sessionStorage.getItem('IMTravelling'));
  let orderDetails = JSON.parse(sessionStorage.getItem('orderGenerated'));
  let GSTDetails = JSON.parse(sessionStorage.getItem('GST'));
  let TIinfo = JSON.parse(sessionStorage.getItem('TIinfo'));
  let offers = JSON.parse(sessionStorage.getItem('offers'));
  let freeCancellation = JSON.parse(sessionStorage.getItem('freeCancellation'));
  if (URL) sessionStorage.removeItem('URL');
  if (userdata) sessionStorage.removeItem('userdata');
  if (travellerinfo) sessionStorage.removeItem('travellerinfo');
  if (addsOn) sessionStorage.removeItem('addsOn');
  if (IMTravelling) sessionStorage.removeItem('IMTravelling');
  if (orderDetails) sessionStorage.removeItem('orderGenerated');
  if (GSTDetails) sessionStorage.removeItem('GST');
  if (TIinfo) sessionStorage.removeItem('TIinfo');
  if (offers) sessionStorage.removeItem('offers');
  if (freeCancellation) sessionStorage.removeItem('freeCancellation');
  let generatedOrderID = sessionStorage.getItem('generatedOrderID');
  let isOrderGenerated = sessionStorage.getItem('isOrderGenerated');
  let OID = sessionStorage.getItem('OID');
  let stepperDetails = sessionStorage.getItem('stepperDetails');
  if (OID) {
    sessionStorage.removeItem('OID');
  }
  if (generatedOrderID) sessionStorage.removeItem('generatedOrderID');
  if (isOrderGenerated) sessionStorage.removeItem('isOrderGenerated');
  if (stepperDetails) {
    sessionStorage.removeItem('stepperDetails');
  }
  sessionStorage.removeItem('tnc');
  sessionStorage.removeItem('Spath');
  sessionStorage.removeItem('isDOBEntered');
  sessionStorage.removeItem('offer_v2');
}

export const commonDisplaytickhandler = (
  e,
  deleteflag,
  ssrProps,
  arrayOrgDest,
  arrayOrgDestState
) => {
  let arrayOrgDestObject = [...arrayOrgDestState];
  arrayOrgDest.map((tab, index) => {
    if (tab != e.title.props.children[0]) {
      const reg = new RegExp(tab);
      const checkvalue = JSON.stringify(ssrProps);
      if (reg.test(checkvalue)) {
        arrayOrgDestObject[index] = {
          ...arrayOrgDestState[index],
          title: (
            <span>
              {tab}{' '}
              <span>
                <img src={'/static/img/ic-check-circle.svg'} />
              </span>
            </span>
          ),
        };
      } else {
        if (deleteflag)
          arrayOrgDestObject[index] = {
            ...arrayOrgDestState[index],
            title: (
              <span>
                {tab}{' '}
                <span style={{ display: 'none' }}>
                  <img src={'/static/img/ic-check-circle.svg'} />
                </span>
              </span>
            ),
          };
      }
    } else {
      arrayOrgDestObject[index] = {
        ...arrayOrgDestState[index],
        title: (
          <span>
            {e.title.props.children[0]}{' '}
            <span style={{ display: 'none' }}>
              <img src={'/static/img/ic-check-circle.svg'} />
            </span>
          </span>
        ),
      };
    }
  });
  return [...arrayOrgDestObject];
};
export const calculatePayableAmt = (
  totalMealsPrice,
  totalBaggagePrice,
  props,
  discountAmount,
  loungeFinalPrice,
  insuranceAmount,
  growTreeAmount
) => {
  let mealsPrice = totalMealsPrice;
  let baggagePrice = totalBaggagePrice;
  let offerAmountDetail =
    props.checked &&
    props.offerAmountDetail &&
    props.offerAmountDetail.type != 'new_offer' &&
    props.offerAmountDetail.bonus;
  let payableAmountInteger =
    parseInt(props.fareDetail.basefare.baseFareTotal.value.substring(1)) +
    parseInt(props.fareDetail.convenienceFee.value.substring(1)) +
    parseInt(props.fareDetail.taxCharges.taxChargesTotal.value.substring(1));
  let discount =
    discountAmount == 0 ? 0 : parseInt(discountAmount.split('₹')[1]);
  let payableAmount = (
    parseInt(payableAmountInteger) -
    discount -
    offerAmountDetail +
    baggagePrice +
    mealsPrice +
    loungeFinalPrice +
    (props.secureTrip ? insuranceAmount : 0) +
    (props.isGrowTreeSelected ? growTreeAmount : 0) +
    (props.isComboSelected ? 199 : 0) +
    (props.seatsTotalFare > 0 ? props.seatsTotalFare : 0) +
    (Object.values(props.isFreeCancellationSelected).indexOf(true) > -1
      ? props.totalFreeCancellationAmount
      : 0)
  ).toFixed(2);
  let totalAmtWithoutDiscount =
    parseInt(payableAmountInteger) +
    baggagePrice +
    mealsPrice +
    loungeFinalPrice +
    (props.secureTrip ? insuranceAmount : 0) +
    (props.isGrowTreeSelected ? growTreeAmount : 0) +
    (props.isComboSelected ? 199 : 0) +
    (props.seatsTotalFare > 0 ? props.seatsTotalFare : 0) +
    (Object.values(props.isFreeCancellationSelected).indexOf(true) > -1
      ? props.totalFreeCancellationAmount
      : 0);
  if (props.swmdata && props.isSwmSelected) {
    payableAmount = parseInt(payableAmount) - parseInt(props.swmdata.d);
  } else {
    payableAmount = parseInt(payableAmount);
  }
  let Payable = Number(payableAmount).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
  return {
    payableAmountInteger,
    payableAmount,
    Payable,
    discount,
    totalAmtWithoutDiscount,
  };
};
export const calculateTotalMiles = (
  props,
  loungeFinalMiles,
  totalMealsMiles,
  totalBaggageMiles,
  insurance_Intermies
) => {
  let totalEarnings = props.fareDetail.earnings.earningDetails[0].value;
  let totalEarningsValue = totalEarnings.substr(0, totalEarnings.indexOf(' '));
  let totalintermiles =
    parseInt(totalEarningsValue) +
    loungeFinalMiles +
    totalMealsMiles +
    totalBaggageMiles +
    (props.secureTrip ? insurance_Intermies : 0) +
    (props.isComboSelected == true ? props.combosMiles : 0) +
    (props.seatsTotalMiles > 0 ? props.seatsTotalMiles : 0) +
    (+props.bonusMiles.length ? props.bonusMiles[0].DISCOUNT_VALUE : 0) +
    (props?.offerAmountDetail?.bonusMiles?.miles || 0);
  if (props.bonusMiles.length > 0) {
    totalintermiles =
      totalintermiles +
      (props.swmOffers?.bonus?.miles
        ? parseInt(props.swmOffers?.bonus?.miles)
        : 0);
  }
  if (
    props.selectedOffer != null &&
    props.selectedOffer?.data?.bonusMiles?.miles > 0
  ) {
    totalintermiles += +props.selectedOffer.data.bonusMiles.miles;
  }
  if (props.tierMiles) {
    totalintermiles += props.tierMiles;
  }
  return totalintermiles;
};
export const discountAmt = (props, firsttimeDiscount, cobrandoffer) => {
  let baseFare = props.fareDetail.basefare.baseFareTotal.value;
  let baseFareInteger = baseFare.substring(1);
  let discountedPrice = 0;
  if (props.checked && props.offerAmt && props.offerAmt > 0) {
    discountedPrice = parseInt(props.offerAmt);
  }
  if (props.offerAmountDetail) {
    if (props.offerOtherValues?.product_type == 'FC') {
      if (props.fcCounter == 0) {
        discountedPrice =
          parseInt(props.fcInfo.apiData[0].paxAmt) *
          Math.min(
            props.maxbenefitlength,
            parseInt(props.offerOtherValues?.product_count)
          );
      } else {
        discountedPrice = 0;
      }
    } else {
      discountedPrice = parseInt(props.offerAmountDetail.bonus || 0);
    }
  }
  if (props.cobrandbankselected) {
    let result = Math.round(
      parseFloat((parseInt(baseFareInteger) * props.cobrandbankselected) / 100)
    );
    discountedPrice = result;
  }
  if (
    props.coBrandOfferData != null &&
    props.coBrandOfferData?.bank &&
    props.coBrandOfferData?.card &&
    props.bookingDetails.onwardDetails[0].rbdPoint
  ) {
    discountedPrice = Math.round(
      parseFloat(
        (parseInt(baseFareInteger) *
          props.bookingDetails.onwardDetails[0].rbdPoint) /
          100
      )
    );
  }
  if (props.ampofferSelected) {
    discountedPrice = parseInt(props.offerAmt);
  }
  if (props.isAmpSelected && props.ampOffers.length > 0) {
    discountedPrice = props.ampOffers[0].discountamt;
  }
  if (
    props.selectedOffer != null &&
    props.selectedOffer.data?.status &&
    props.selectedOffer.data?.bonus_type != 'miles' &&
    props.selectedOffer.data?.bonus &&
    props.selectedOffer.data?.bonus != ''
  ) {
    discountedPrice = props.selectedOffer.data?.bonus;
  }
  if (
    props.selectedOffer?.data?.validationRepsonse?.values?.otherValues
      ?.product_type == 'FC'
  ) {
    if (props.fcCounter == 0) {
      discountedPrice =
        parseInt(props.fcInfo.apiData[0].paxAmt) *
        Math.min(
          props.maxbenefitlength,
          parseInt(
            props.selectedOffer?.data?.validationRepsonse?.values?.otherValues
              ?.product_count
          )
        );
    } else {
      discountedPrice = 0;
    }
  }
  // promocode discount
  discountedPrice = `- ₹ ${(
    parseInt(discountedPrice) +
    parseInt(firsttimeDiscount || 0) +
    parseInt(cobrandoffer || 0)
  ).toFixed(2)}`;
  return discountedPrice;
};
export async function emailSmsService(data, orderId, bookingType) {
  let mobile = '';
  let textMessage = '';
  if (data.mobile != null) {
    mobile = data.mobileSmsCountryCode + data.mobile.split(' ').join('');
    textMessage = 'SMS';
  }
  let email = [];
  if (data.email != null) {
    email = data.email.split(',');
    textMessage =
      'Email' + (textMessage != '' ? '/' + textMessage : textMessage);
  }
  const mailSmsPayload = {
    ORDER_ID: orderId,
    emailto: email,
    email:
      bookingType === 'UBA'
        ? 'UB_A_Booking_Success_New_1'
        : 'UB_R_Booking_Success_New_1',
    voucher:
      bookingType === 'UBA' ? 'Voucher_Redesign_UBA' : 'Voucher_Redesign_UBR',
    invoice: 'UB_TAX_INVOICE',
    mobileto: [mobile],
    mobile:
      bookingType === 'UBA'
        ? 'UB_A_Booking_Success_New_1'
        : 'UB_R_Booking_Success_New_1',
    bookingtype: bookingType,
    requestSource: 'API',
  };
  const newmailSmsPayload = {
    orderId: orderId,
    emailTo: email,
    email:
      bookingType === 'UBA'
        ? 'UB_A_Booking_Success_New_1'
        : 'UB_R_Booking_Success_New_1',
    voucher:
      bookingType === 'UBA' ? 'Voucher_Redesign_UBA' : 'Voucher_Redesign_UBR',
    invoice: 'UB_TAX_INVOICE',
    mobileTo: [mobile],
    mobile:
      bookingType === 'UBA'
        ? 'UB_A_Booking_Success_New_1'
        : 'UB_R_Booking_Success_New_1',
    bookingType: bookingType,
    requestSource: 'API',
  };

  // const emailSmsRes = await service(mailSmsPayload, sendEmailSms);
  const emailSmsRes = await newService(
    newmailSmsPayload,
    'sendEmailSms',
    '/glidePrice/sendEmailSms'
  );
  return {
    emailSmsRes,
    textMessage,
  };
}
export function setTravellerState(
  data,
  type,
  index,
  ffpIndex,
  invaliddate,
  travllerInfo
) {
  switch (type) {
    case 'title':
      travllerInfo[index].title = data;
      travllerInfo[index].titleError = false;
      travllerInfo[index].isUniqueData = true;
      break;
    case 'firstName':
      travllerInfo[index].firstName = data;
      travllerInfo[index].fNameError = travllerInfo[index].isFnameValid = false;
      travllerInfo[index].isUniqueData = true;
      break;
    case 'lastName':
      travllerInfo[index].lastName = data;
      travllerInfo[index].lnameError = travllerInfo[index].isLnameValid = false;
      travllerInfo[index].isUniqueData = true;
      break;
    case 'dob':
      if (invaliddate) {
        travllerInfo[index].dobpresent =
          travllerInfo[index].dob != null ? '' : data;
        travllerInfo[index].dateError = travllerInfo[index].isDateValid = false;
        // changes as a part of Improvement GPD-429
        //travllerInfo[index].dob = null;
      } else {
        sessionStorage.setItem('isDOBEntered', true);
        travllerInfo[index].dateError = travllerInfo[index].isDateValid = false;
        travllerInfo[index].dob = data;
        travllerInfo[index].dobpresent = travllerInfo[index].dob? true:false;
      }
      break;
    case 'passport':
      travllerInfo[index].passportError = travllerInfo[
        index
      ].isPassportValid = false;
      travllerInfo[index].isUniquePassport = true;
      travllerInfo[index].passport = data;
      travllerInfo[index].isUniqueData = true;
      break;
    case 'ffpNumber':
      travllerInfo[index].FFPDetails[ffpIndex].ffpNumber = data;
      travllerInfo[index].ffpNumber = data;
      break;
    case 'ffpAirline':
      travllerInfo[index].FFPDetails[ffpIndex].ffpAirline = data;
      travllerInfo[index].FFPDetails[ffpIndex] = {
        ...travllerInfo[index].FFPDetails[ffpIndex],
        ffpAirline:
          data.airlineName != 'Frequent Flier Program' ? data.airlineName : '',
        ffpAirlineCode: data.airlineCode,
      };
      break;
    case 'passportExipryDate':
      travllerInfo[index].passportExpiryDate = data;
      travllerInfo[index].isPassportExpiryRequired = false;
      break;
    default:
      break;
  }
  return travllerInfo;
}
export function mobileValidation(mobile, isValidated, isd) {
  let mobileValidationObj = {
    isMobileError: false,
    isMobileInvalid: false,
  };
  if (mobile === '') {
    mobileValidationObj.isMobileError = true;
  }
  if (
    !mobileValidationObj.isMobileError &&
    !isValidated &&
    (isd == '91'
      ? !mobile.match(/^[0]?[6789]\d{9}$/)
      : !mobile.match(/^[0-9]\d{5,14}$/))
  ) {
    mobileValidationObj.isMobileInvalid = true;
  }
  return mobileValidationObj;
}
export function saveToStorage(type, searchType, stateVariable) {
  let StateObject;
  let StateType = type;
  switch (type) {
    case 'userdata':
      StateObject = {
        email: stateVariable.email,
        mobile: stateVariable.mobile,
        isd: stateVariable.isd,
        isEmailError: stateVariable.isEmailError,
        isMobileError: stateVariable.isMobileError,
        isInvalidEmail: stateVariable.isInvalidEmail,
        isMobileInvalid: stateVariable.isMobileInvalid,
      };
      if (searchType == 'UBA') {
        StateObject = {
          ...StateObject,
          isValidated: stateVariable.isValidated,
          ddd: stateVariable.dff,
          modal_status: stateVariable.modal_status,
          prospectIMNumber: stateVariable.prospectIMNumber,
          validationInput: stateVariable.validationInput,
          validateUser: stateVariable.validateUser.data ? true : false,
        };
      }
      break;
    case 'travellerinfo':
      StateObject = {
        travellerInfo: stateVariable.travellerInfo,
        isTitleError: stateVariable.isTitleError,
        isFirstNameError: stateVariable.isFirstNameError,
        isLastNameError: stateVariable.isLastNameError,
      };
      break;
    case 'addsOn':
      StateObject = {
        isComboSelected: stateVariable.isComboSelected,
        combosMiles: stateVariable.combosMiles,
        baggageValue: stateVariable.baggageValue,
        baggagePrice: stateVariable.baggagePrice,
        baggageMiles: stateVariable.baggageMiles,
        mealsValue: stateVariable.mealsValue,
        mealsValuePrice: stateVariable.mealsValuePrice,
        mealsValueMiles: stateVariable.mealsValueMiles,
        mealsPrice: stateVariable.mealsPrice,
        mealsMiles: stateVariable.mealsMiles,
        checkedLoungeAccess: stateVariable.checkedLoungeAccess,
        checkedLoungeMiles: { ...stateVariable.checkedLoungeMiles },
        checkedLoungePrice: stateVariable.checkedLoungePrice,
        seats: { ...stateVariable.seats },
        travellerInfo: stateVariable.travellerInfo,
        seatsTotalFare: stateVariable.seatsTotalFare,
        seatsTotalMiles: stateVariable.seatsTotalMiles,
        dataSsrMain: stateVariable.dataSsrMain,
        tabheadersDomestic: stateVariable.tabheadersDomestic,
        freemealselected: stateVariable.freemealselected,
        freebaggageselected: stateVariable.freebaggageselected,
        freeloungeselected: stateVariable.freeloungeselected,
        membermealselected: stateVariable.membermealselected,
        memberbaggageselected: stateVariable.memberbaggageselected,
        memberloungeselected: stateVariable.memberloungeselected,
        freeseatselected: stateVariable.freeseatselected,
        mealsPricetemp: stateVariable.mealsPricetemp,
        mealsMilestemp: stateVariable.mealsMilestemp,
        mealskey: stateVariable.mealskey,
        baggagekey: stateVariable.baggagekey,
        baggagePricetemp: stateVariable.baggagePricetemp,
        baggageMilestemp: stateVariable.baggageMilestemp,
        checkedLoungePricetemp: stateVariable.checkedLoungePricetemp,
        checkedLoungeMilestemp: stateVariable.checkedLoungeMilestemp,
        mealsDisplay: stateVariable.mealsDisplay,
        mealsCounterType: stateVariable.mealsCounterType,
        baggageDisplay: stateVariable.baggageDisplay,
        baggageCountType: stateVariable.baggageCountType,
      };
      if (searchType == 'UBA') {
        StateObject = {
          ...StateObject,
          baggagediscountamt: stateVariable.baggagediscountamt,
        };
      }
      break;
    case 'IMTravelling':
      StateObject = {
        isImTravelling: stateVariable.isImTravelling,
      };
      break;
    case 'orderGenerated':
      StateObject = {
        isOrderGenerated: stateVariable.orderInfo.isOrderGenerated,
        generatedOrderID: stateVariable.orderInfo.generatedOrderID,
      };
      sessionStorage.setItem(
        'isOrderGenerated',
        stateVariable.orderInfo.isOrderGenerated
      );
      sessionStorage.setItem(
        'generatedOrderID',
        stateVariable.orderInfo.generatedOrderID
      );
      if (searchType == 'UBA') {
        sessionStorage.setItem('OID', stateVariable.orderInfo.OID);
      }
      break;
    case 'GST':
      StateObject = {
        gst: stateVariable.gst,
        gst_company: stateVariable.gst_company,
      };
      break;
    case 'TIinfo':
      StateObject = {
        secureTrip: stateVariable.secureTrip,
        insuranceAmount: stateVariable.insuranceAmount,
        insurance_Intermies: stateVariable.insurance_Intermies,
        insurance_daysErr: stateVariable.insurance_daysErr,
        insurancedays: stateVariable.insurancedays,
      };
      break;
    case 'offers':
      StateObject = {
        offerAmountDetail: stateVariable.offerAmountDetail,
        promocodemsg: stateVariable.promocodemsg,
        promocode: stateVariable.promocode,
        promocodevalue: stateVariable.promocodevalue,
        voucherNo: stateVariable.voucherNo,
        checked: stateVariable.checked,
        index: stateVariable.index,
        iscobrand: stateVariable.iscobrand,
        cobrandbankselected: stateVariable.cobrandbankselected,
        offerDiscount: stateVariable.offerDiscount,
        cobrandoffer: stateVariable.cobrandoffer,
        cobrandofferrequest: stateVariable.cobrandofferrequest,
        bankselected: stateVariable.bankselected,
        bin_range: stateVariable.bin_range,
        ID: stateVariable.ID,
        Card_Variant: stateVariable.Card_Variant,
        swmdata: stateVariable.swmdata,
        cobrandindex: stateVariable.cobrandindex,
        iscobrandselected: stateVariable.iscobrandselected,
        offerAmt: stateVariable.offerAmt,
        offerResp: stateVariable.offerResp,
        ampoffers: stateVariable.ampoffers,
        ampofferSelected: stateVariable.ampofferSelected,
        isEarnSelected: stateVariable.isEarnSelected,
        isSwmSelected: stateVariable.isSwmSelected,
        isSwmIndex: stateVariable.swmdata != null ? stateVariable.swmdata.i : 0,
        offerSelected: stateVariable.offerSelected,
        bonusTypeMile: stateVariable.bonusTypeMile,
        offerOtherValues: stateVariable.offerOtherValues,
      };
      break;
    case 'freeCancellation':
      StateObject = {
        isFreeCancellationSelected: stateVariable.isFreeCancellationSelected,
        totalFreeCancellationAmount: stateVariable.totalFreeCancellationAmount,
        fcCounter: stateVariable.fcCounter,
        maxbenefitlength: stateVariable.maxbenefitlength,
        paxBenefitLength: stateVariable.paxBenefitLength,
      };
      break;
    case 'Spath':
      StateObject = {
        ssrPriceSessionPath: stateVariable.ssrPriceSessionPath,
      };
      break;
    case 'stepperDetails':
      StateObject = {
        ProgressSteps: stateVariable.ProgressSteps,
        isPayNowEnable: stateVariable.isPayNowEnable,
        selectedTabSsr: stateVariable.selectedTabSsr,
      };
      break;
    case 'tnc':
      StateObject = {
        tncForBooking: stateVariable.tncForBooking,
        firstTimeError: stateVariable.firstTimeError,
        isTNCError: stateVariable.isTNCError,
        showTermConditionError: stateVariable.showTermConditionError,
      };
      break;
    case 'offer_v2':
      StateObject = {
        selectedOffer: stateVariable.selectedOffer,
        promocodevalue: stateVariable.promocodevalue,
        paymentType: stateVariable.paymentType,
        swmSelectedOffer: stateVariable.swmSelectedOffer,
        iscobrandselected: stateVariable.iscobrandselected,
        coBrandOfferData: stateVariable.coBrandOfferData,
        coBrandBankCard: stateVariable.coBrandBankCard,
        offerOtherValues: stateVariable.offerOtherValues,
        sliderValue: stateVariable.sliderValue,
        swmFareDiscount: stateVariable.swmFareDiscount,
        swmMilesToRedeem: stateVariable.swmMilesToRedeem,
        insufficientSwmMiles: stateVariable.insufficientSwmMiles,
      };
      break;
    case 'growTreeInfo':
      StateObject = {
        isGrowTreeSelected: stateVariable.isGrowTreeSelected,
      };
    default:
      break;
  }
  if (type !== 'orderGenerated') {
    sessionStorage.setItem(StateType, JSON.stringify(StateObject));
  }
}
export function dateFormat(date) {
  let dob = new Date(date);
  let mm = dob.getMonth() + 1;
  let dd = dob.getDate();
  let yy = dob.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }
  if (mm < 10) {
    mm = `0${mm}`;
  }
  return `${dd}-${mm}-${yy}`;
}
export function gstValidation(gstNo) {
  let isValid = false;
  let openGSTSection = true;
  if (gstNo != null || gstNo != '') {
    if (new RegExp('^[(a-zA-Z0-9s)]{15}$').test(gstNo) || gstNo?.length == 0) {
      isValid = true;
      openGSTSection = false;
    }
  }
  return {
    isValid,
    openGSTSection,
  };
}
export function emailValidation(userEmail) {
  let EmailState = {
    isEmailError: false,
    isInvalidEmail: false,
  };
  if (userEmail == '') {
    EmailState.isEmailError = true;
  }
  if (
    !EmailState.isEmailError &&
    !userEmail.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
  ) {
    EmailState.isInvalidEmail = true;
  }
  return EmailState;
}
export async function checkingRequiredValidation(
  travellerState,
  reqPara,
  validationPara,
  keyToCheck,
  reqState,
  ValidState,
  stateFlag,
  uniqueFlag
) {
  let updatedTraveller = travellerState?.map((traveller) => {
    if (
      traveller[keyToCheck] !== reqPara &&
      traveller[keyToCheck].trim().length !== 0
    ) {
      if (validationPara) {
        traveller = checkingValidValidation(
          traveller,
          validationPara,
          traveller[keyToCheck],
          ValidState
        );
      }
      traveller[reqState] = false;
    } else {
      traveller[reqState] = true;
      traveller[ValidState] = false;
      if (uniqueFlag) {
        traveller[uniqueFlag] = true;
      }
    }
    return traveller;
  });
  return updatedTraveller;
}
function checkingValidValidation(
  objectState,
  regex_,
  dataToMatch,
  invalidStateFlag
) {
  objectState[invalidStateFlag] = true;
  if (new RegExp(regex_).test(dataToMatch)) {
    objectState[invalidStateFlag] = false;
  }
  return {
    ...objectState,
  };
}
async function checkPassportError(travellerInfo, isInternational) {
  try {
    if (isInternational) {
      let updateTravellerInfo = await checkingRequiredValidation(
        travellerInfo,
        '',
        '^[(a-zA-Z0-9)]{1,15}$',
        'passport',
        'passportError',
        'isPassportValid',
        'PassPortError',
        'isUniquePassport'
      );
      for (let index = 0; index < updateTravellerInfo.length; index++) {
        // logger(updateTravellerInfo[index].passportError);
        // logger(updateTravellerInfo[index].isPassportValid);
        if (
          !updateTravellerInfo[index].passportError &&
          !updateTravellerInfo[index].isPassportValid
        ) {
          let idx = updateTravellerInfo.findIndex(
            (t) => t.passport == updateTravellerInfo[index].passport
          );
          if (index !== idx) {
            updateTravellerInfo[index].isUniquePassport = false;
          } else {
            updateTravellerInfo[index].isUniquePassport = true;
          }
        }
      }
      return updateTravellerInfo;
    }
    return travellerInfo;
  } catch (error) {}
}
function uniqueTravellerValidation(travellers) {
  try {
    let paxInfo = [];
    for (let index = 0; index < travellers.length; index++) {
      let traveller = travellers[index];
      for (let i = 0; i < travellers.length; i++) {
        if (i !== index && i < index) {
          let pax = travellers[i];
          let isuniqueName = 
            (pax.firstName &&
            traveller.firstName !== '' &&
            pax.lastName &&
            traveller.lastName !== '' &&
            pax.title !== 'Title' &&
            pax.title == traveller.title &&
            pax.firstName.trim() == traveller.firstName.trim() &&
            pax.lastName.trim() == traveller.lastName.trim()) ? false : true
          traveller.isUniqueData = true;
          if (!isuniqueName) {
            traveller.isUniqueData = false;
            break;
          }
        } else {
          traveller.isUniqueData = true;
        }
      }
      paxInfo.push(traveller);
    }
    return paxInfo;
  } catch (error) {
    logger(error, 'error');
  }
}
export function addOnItemsOnBCP(MMB, props) {
  let ssrArray = [];
  !MMB &&
    props.bookingSummary &&
    props.bookingSummary.segment.map((seg) => {
      seg.legs.map((leg) => {
        leg.travellers.map((traveller) => {
          if (
            leg.isFreeCancellation !== false &&
            ssrArray.indexOf('Free Cancellation') === -1
          ) {
            ssrArray.push('Free Cancellation');
          }
          if (
            traveller.tiPolicy !== 'Not Opted' &&
            ssrArray.indexOf('Travel Insurance') === -1
          ) {
            ssrArray.push('Travel Insurance');
          }
          if (
            Object.keys(props.comboOffer).length > 0 &&
            ssrArray.indexOf('Combos') === -1
          ) {
            ssrArray.push('Combos');
          }
          if (
            traveller.mealDesc !== 'Not Opted' &&
            ssrArray.indexOf('Meals') === -1
          ) {
            ssrArray.push('Meals');
          }
          if (
            traveller.baggageDesc !== 'Not Opted' &&
            ssrArray.indexOf('Baggage') === -1
          ) {
            ssrArray.push('Baggage');
          }
          if (traveller.seatDesc !== 'NA' && ssrArray.indexOf('Seats') === -1) {
            ssrArray.push('Seats');
          }
          if (
            traveller.loungeDesc !== 'Not Opted' &&
            ssrArray.indexOf('Lounge') === -1
          ) {
            ssrArray.push('Lounge');
          }
        });
      });
    });
  return ssrArray;
}
export function toTitleCase(str) {
  return str?.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

function checkDOBError(travellerInfo, isInternational) {
      for (let index = 0; index < travellerInfo.length; index++){
        if(isInternational || travellerInfo[index].paxType !== 'ADT'){
         if(travellerInfo[index].dobpresent && travellerInfo[index].dob){
          travellerInfo[index].dateError = false
         }
         else{
          travellerInfo[index].dateError = true
         }
      }
    }
    return travellerInfo
}
export async function bookingFormValidation(
  travellerInfo,
  isInternational,
  bookingDetails
) {
  let travellerTitle = await checkingRequiredValidation(
    travellerInfo,
    'Title',
    false,
    'title',
    'titleError',
    false,
    'isTitleError'
  );
  let travellerFName = await checkingRequiredValidation(
    travellerTitle,
    '',
    '^[(a-z A-Z s)]{1,30}$',
    'firstName',
    'fNameError',
    'isFnameValid',
    'isFirstNameError'
  );
  let travellerLName = await checkingRequiredValidation(
    travellerFName,
    '',
    '^[(a-z A-Z s)]{1,30}$',
    'lastName',
    'lnameError',
    'isLnameValid',
    'isLastNameError'
  );
  let travellerDob = await checkDOBError(
    travellerLName,
    isInternational
  );
  let travellerPassport = await checkPassportError(
    travellerDob,
    isInternational
  );
  let travellerUnique = await uniqueTravellerValidation(
    travellerPassport
  );
  return passportExpiryValidation(
    travellerUnique,
    isInternational
  );
}
async function passportExpiryValidation(
  travellerArray,
  isInternational
) {
  try {
    return [...travellerArray].map((traveller) => {
      if (isInternational) {
          if(!traveller.passportExpiryDate){
            traveller.isPassportExpiryRequired = true;
          }   
      }
      return traveller;
    });
  } catch (error) {
    logger('error', error);
  }
}
export function contactValidation(data, type, isValidated) {
  let errorState = {
    isValidated: false,
    isMobileError: false,
    isMobileInvalid: false,
    isInvalidEmail: false,
    isEmailError: false,
  };
  if (type == 'mobile') {
    logger('isValidated', isValidated);
    logger('isValidated', data.match(/^[0]?[6789]\d{9}$/));
    if (data !== '' && data.match(/^[0]?[6789]\d{9}$/) && isValidated) {
      errorState.isValidated = true;
    }
  } else if (type == 'email') {
    if (data.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/) && isValidated) {
      errorState.isValidated = true;
    }
  }
  return errorState;
}
export function seatDynamiHandling(arrayOrgDest, travellerInfo, seats) {
  let seatFlights = {};
  let updateSeatState = Object.assign({}, { ...seats });
  // dynamically adding key in travellerinfo state for displaying dynamic seat no - @Niket
  for (const seatLeg of arrayOrgDest) {
    seatFlights = {
      ...seatFlights,
      [seatLeg]: '',
      [seatLeg + '_isSeatSelected']: false,
    };
    // dynamic seats array based on flights
    updateSeatState = {
      ...updateSeatState,
      [seatLeg]: [],
    };
  }
  let upd_travaller = travellerInfo.map((traveller) => {
    let updated_traveller;
    updated_traveller = {
      ...traveller,
      seats: {
        ...seatFlights,
      },
    };
    return updated_traveller;
  });
  return {
    updateSeatState,
    upd_travaller,
  };
}
export function imTravelling(
  travellerInfo,
  loggedIn_Info,
  isImTravelling,
  travelerDetails
) {
  // let email = '';
  // let phoneno = '';
  if (loggedIn_Info.isLoggedIn) {
    if (!isImTravelling) {
      travellerInfo[0].firstName = loggedIn_Info.first_name;
      travellerInfo[0].lastName = loggedIn_Info.last_name;
      if (memberTitleCheck(loggedIn_Info.member_title, travelerDetails)) {
        travellerInfo[0].title = loggedIn_Info.member_title;
      }
      // email = loggedIn_Info.email;
      // phoneno = loggedIn_Info.mobile_number.slice(2);
    } else {
      travellerInfo[0].firstName = '';
      travellerInfo[0].lastName = '';
      travellerInfo[0].title = 'Title';
    }
  }
  return {
    travellerInfo,
    // email,
    // phoneno
  };
}
function memberTitleCheck(adultTitle, travelerDetails) {
  let accountHolderTitle = travelerDetails?.adultDetails[0]?.title;
  if (accountHolderTitle) {
    let titleFilter = accountHolderTitle?.filter(
      (title) => title == adultTitle
    );
    return titleFilter.length > 0;
  }
}
export function paymentFailed(oid) {
  let request = {
    orderID: oid,
    requestSource: 'API',
  };
  // service(request, bookingFailedService);
  newService(
    oid,
    'bookingFailedService',
    '/glidePrice/payment/booking-failed'
  );

  sessionStorage.removeItem('generatedOrderID');
  sessionStorage.removeItem('isOrderGenerated');
  sessionStorage.removeItem('OID');
  sessionStorage.removeItem('stepperDetails');
  sessionStorage.removeItem('tnc');
}
export function dynamicTravellerState(
  reviewFlight,
  loggedIn_Info,
  paxKey,
  paxType,
  flightId,
  paxTitleKey
) {
  let traveller = [];
  for (let i = 0; i < reviewFlight?.travellerDetails?.[paxKey]?.length; i++) {
    let FFPDetailsArray = [];
    reviewFlight.travellerDetails[paxKey][i].ffpDetails.forEach(() => {
      FFPDetailsArray.push({
        ffpNumber: '',
        ffpAirline: '',
        ffpAirlineCode: '',
      });
    });
    traveller.push({
      title: 'Title',
      firstName: '',
      lastName: '',
      titleError: false,
      fNameError: false,
      isFnameValid: false,
      isUniquePassport: true,
      lnameError: false,
      isFlightFlierOpen: false,
      isLnameValid: false,
      passportError: false,
      dateError: false,
      isDateValid: false,
      isPassportValid: false,
      seatNo: '',
      cabinClass:
        reviewFlight?.bookingDetails[0]?.cabinClass == 'Economy' ? 'E' : 'B',
      dob: null,
      defaultText: reviewFlight.travellerDetails[paxKey][i][paxTitleKey],
      passport: '',
      PID: loggedIn_Info.PID,
      fareProductCategory: reviewFlight.fareDetail.fareProduct,
      URL: flightId,
      paxType: paxType,
      isUniqueData: true,
      FFPDetails: FFPDetailsArray,
      passportExpiryDate: null,
      isPassportExpiryRequired: false,
      isInfant: paxType == 'INF' ? true : false,
    });
  }
  return traveller;
}
export function offerCopyCode(id, obj, index, props) {
  // Adobe code for offers click event starts
  let offerAdobeRequest = {
    eventInfo: {
      eventName: 'offers and deals click',
    },
    tabName: '',
    brickInfo: {
      brickType: 'Flights',
      partnerName: obj.partnerName || '',
      partnerCode: obj.partnerCode || '',
      offersDisplayed: obj.description || '',
      offersExpire: obj.validTill || '',
      offersPosition: index + 1,
    },
    offerInfo: {
      offerName: obj.discountTitle || '',
    },
    promoCode: obj.couponCode || '',
  };
  Adobe.offersClickHomepageSRP(
    offerAdobeRequest,
    props.pageInfo,
    props.loggedIn_Info
  );
  var r = document.createRange();
  r.selectNode(document.getElementById(id));
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(r);
  document.execCommand('copy');
  window.getSelection().removeAllRanges();
  return document.getElementById(id).innerText;
}
function initialSortState(
  searchType,
  tripType,
  isMobile,
  isFareDetailRequired
) {
  let sortingStateObject = {
    airline: {
      key: 'airline',
      name: 'AIRLINE',
      filter: 'AIRLINE',
      up: false,
      down: false,
      nonActiveCss: 'sortairlinerow',
      activeCss: 'sortairlinerow sortactivetab',
      isActive: false,
      webPosition: 1,
      isMobileDisable: true,
      isWebEnable: true,
    },
    depature: {
      key: 'depart',
      name: 'DEPARTURE',
      filter: 'DEPARTURE',
      up: false,
      down: false,
      nonActiveCss: 'sortdeparturerow',
      activeCss: 'sortdeparturerow sortactivetab',
      isActive: false,
      webPosition: 2,
      isWebEnable: true,
    },
    duration: {
      key: 'duration',
      name: 'DURATION',
      filter: 'DURATION',
      up: false,
      down: false,
      nonActiveCss: 'sortdurationrow',
      activeCss: 'sortdurationrow sortactivetab',
      isActive: false,
      webPosition: 3,
      isWebEnable: true,
    },
    arrival: {
      key: 'arrive',
      name: 'ARRIVAL',
      filter: 'ARRIVAL',
      up: false,
      down: false,
      nonActiveCss: 'sortarrivalrow',
      activeCss: 'sortarrivalrow sortactivetab',
      isActive: false,
      webPosition: 4,
      isWebEnable: true,
    },
    miles: {
      key: 'miles',
      name: 'MILES',
      filter: 'MILES',
      up: false,
      down: false,
      nonActiveCss: 'sortmilesrow',
      activeCss: 'sortmilesrow sortactivetab',
      isActive: false,
      webPosition: 5,
      isWebEnable: true,
      isUBA: true,
    },
    price: {
      key: 'ppa',
      name: '<strong>PRICE</strong> PER ADULT',
      filter: 'PRICEADULT',
      up: true,
      down: false,
      cssClassName: true,
      nonActiveCss: 'sortpricerow',
      activeCss: 'sortpricerow sortactivetab',
      isActive: true,
      webPosition: 6,
      isUBA: true,
      isWebEnable: true,
    },
    fare: {
      key: 'FARE',
      name: 'FARE PER TRAVELLER',
      up: false,
      down: false,
      isActive: false,
      cssClassName: true,
      isUBR: true,
      webPosition: 6,
      isWebEnable: true,
      activeCss: 'sortpricerow sortactivetab',
      nonActiveCss: 'sortpricerow',
    },
  };
  if (isMobile && searchType == 0) {
    sortingStateObject.depature = {
      ...sortingStateObject.depature,
      upText: 'Earliest First',
      downText: 'Latest First',
      mobName: 'Departure',
      mobPosition: 3,
    };
    sortingStateObject.duration = {
      ...sortingStateObject.duration,
      upText: 'Shortest First',
      downText: 'Longest First',
      mobName: 'Duration',
      mobPosition: 2,
    };
    sortingStateObject.arrival = {
      ...sortingStateObject.arrival,
      upText: 'Earliest First',
      downText: 'Latest First',
      mobName: 'Arrival',
      mobPosition: 4,
    };
    sortingStateObject.price = {
      ...sortingStateObject.price,
      upText: 'Cheapest First',
      downText: 'Expensive First',
      mobName: 'Price Per Adult',
      mobPosition: 1,
    };
    sortingStateObject.miles = {
      ...sortingStateObject.miles,
      upText: 'Lowest First',
      downText: 'Highest First',
      mobName: 'Miles Earning',
      mobPosition: 5,
    };
  }
  if (searchType == 0) {
    if (tripType == 3) {
      sortingStateObject.airline = {
        ...sortingStateObject.airline,
        nonActiveCss: 'sortairlinerowRetIntern',
        activeCss: 'sortairlinerowRetIntern sortactivetab',
      };
      sortingStateObject.depature = {
        ...sortingStateObject.depature,
        nonActiveCss: 'sortdeparturerowRetIntern',
        activeCss: 'sortdeparturerowRetIntern sortactivetab',
      };
      sortingStateObject.duration = {
        ...sortingStateObject.duration,
        nonActiveCss: 'sortdurationrowRetIntern',
        activeCss: 'sortdurationrowRetIntern sortactivetab',
      };
      sortingStateObject.arrival = {
        ...sortingStateObject.arrival,
        nonActiveCss: 'sortarrivalrowRetIntern',
        activeCss: 'sortarrivalrowRetIntern sortactivetab',
      };
      sortingStateObject.miles = {
        ...sortingStateObject.miles,
        nonActiveCss: 'sortmilesrowRetIntern',
        activeCss: 'sortmilesrowRetIntern sortactivetab',
      };
      sortingStateObject.price = {
        ...sortingStateObject.price,
        nonActiveCss: 'sortpricerowRetIntern',
        activeCss: 'sortpricerowRetIntern sortactivetab',
      };
    } else if (tripType == 2) {
      sortingStateObject.airline = {
        ...sortingStateObject.airline,
        nonActiveCss: 'sortairlinerowRetOnwrd',
        activeCss: 'sortairlinerowRetOnwrd sortactivetab',
      };
      sortingStateObject.depature = {
        ...sortingStateObject.depature,
        nonActiveCss: 'sortdeparturerowRetOnwrd',
        activeCss: 'sortdeparturerowRetOnwrd sortactivetab',
      };
      sortingStateObject.duration = {
        ...sortingStateObject.duration,
        nonActiveCss: 'sortdurationrowRetOnwrd',
        activeCss: 'sortdurationrowRetOnwrd sortactivetab',
      };
      sortingStateObject.arrival = {
        ...sortingStateObject.arrival,
        nonActiveCss: 'sortarrivalrowRetOnwrd',
        activeCss: 'sortarrivalrowRetOnwrd sortactivetab',
      };
      sortingStateObject.miles = {
        ...sortingStateObject.miles,
        nonActiveCss: 'sortmilesrowRetOnwrd',
        activeCss: 'sortmilesrowRetOnwrd sortactivetab',
      };
      sortingStateObject.price = {
        ...sortingStateObject.price,
        nonActiveCss: 'sortpricerowRetOnwrd',
        activeCss: 'sortpricerowRetOnwrd sortactivetab',
      };
    }
  } else {
    sortingStateObject.airline.key = 'AIRLINE';
    sortingStateObject.depature.key = 'DEPARTURE';
    sortingStateObject.duration.key = 'DURATION';
    sortingStateObject.arrival.key = 'ARRIVAL';
  }
  let sorting = Object.values(sortingStateObject);
  let sortSearchTypeKey = searchType == 0 ? 'isUBR' : 'isUBA';
  sorting = sorting.filter((sort) => {
    return (
      !sort[sortSearchTypeKey] &&
      (isMobile ? !sort.isMobileDisable : sort.isWebEnable)
    );
  });
  if (searchType == 1 && !isFareDetailRequired) {
    let indexOfFare = sorting.findIndex((sort) => sort.key == 'FARE');
    sorting.splice(indexOfFare, 1);
  }
  sorting = sorting.sort((a, b) => {
    return (
      a[isMobile ? 'mobPosition' : 'webPosition'] -
      b[isMobile ? 'mobPosition' : 'webPosition']
    );
  });
  return sorting;
}
export function buildSortState(searchType, tripType, isMobile) {
  let sorting = {};
  if (searchType == 0) {
    if (tripType == 2) {
      sorting = {
        onward: initialSortState(searchType, tripType, isMobile),
        return: initialSortState(searchType, tripType, isMobile),
      };
    } else {
      sorting = {
        onward: initialSortState(searchType, tripType, isMobile),
      };
    }
  } else {
    if (tripType != 3) {
      sorting = {
        standard: initialSortState(
          searchType,
          tripType,
          isMobile,
          config.redeemV2ApiCall
        ),
        flexy: initialSortState(searchType, tripType, isMobile, true),
      };
    } else {
      sorting = {
        standard: {
          onward: initialSortState(searchType, tripType, isMobile),
          return: initialSortState(
            searchType,
            tripType,
            isMobile,
            config.redeemV2ApiCall
          ),
        },
        flexy: {
          onward: initialSortState(searchType, tripType, isMobile),
          return: initialSortState(searchType, tripType, isMobile, true),
        },
      };
    }
  }
  return sorting;
}

export function getCookieValue1(cname, ckey) {
  // console.log("cookie cookie")
  if (typeof $.cookie(cname) !== 'undefined') {
    var valuearray = unescape($.cookie(cname)).split('&');
    for (var i = 0; i < valuearray.length; i++) {
      if (valuearray[i].indexOf(ckey) >= 0) {
        var keyval = valuearray[i].split('=');
        if (keyval.length > 1) {
          return keyval[1];
        }
      }
    }
  }
  return '';
}

export function calculateVia(leg, isStop) {
  const expectedvia = leg[leg.length - 1]?.des;
  if (leg.length > 0) {
    let viaResult = [];
    for (const viaInfo of leg) {
      if (viaInfo.loa != null && viaInfo.leglod != null) {
        viaResult.push(viaInfo.loa);
      }
      if (leg.length > 1 && viaInfo.des != expectedvia) {
        viaResult.push(viaInfo.des);
      }
    }
    if (!isStop)
      return viaResult.length > 0 ? 'via ' + viaResult.join() : 'Non-Stop';
    return viaResult.length;
  }
}
export const calculateDiscount = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  let condition = isReviewRefundPartial
    ? (props.refundableDetails.onwardJourney || props.refundableDetails.returnJourney) &&
      onward
    : (props.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0) ||
      (props.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0);
  let isOnwardDiscount = isDiscountAvailed(onward);
  if (isOnwardDiscount) {
    if (condition) {
      total += onward[0]?.discountAvailed;
    }
  }
  return total;
};
export const isDiscountAvailed = (array) => {
  let discountAvailed = false;
  array?.map((pax) => {
    if (pax.discountAvailed > 0) {
      discountAvailed = true;
    }
  });
  return discountAvailed;
};
// To be done
export const caculateTotalDeductible = (
  onward,
  ret,
  onwardPax,
  returnPax,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  total += caculateIMFee(onward, ret, isReviewRefundPartial, props, state);
  total += caculateAirLineFee(
    onward,
    ret,
    onwardPax,
    false,
    isReviewRefundPartial,
    props,
    state
  );
  total += caculateIMConvenienceFee(
    onward,
    ret,
    isReviewRefundPartial,
    props,
    state
  );
  // if (!this.props.isBookingAlreadyCancelled) {
  //     total += this.calculateDiscount(onward, ret);
  // }
  if (!props.isUBR) {
    total += calculateAncillaries(
      onward,
      ret,
      isReviewRefundPartial,
      props,
      state
    );
    // total += this.calculateInsuranceAmount(onward, ret).totalAmount;
  }
  if (
    !props.isUBR &&
    !props.isBookingAlreadyCancelled &&
    onward &&
    props.isSwmOldLogic
  ) {
    total += onward[0].discountAvailed;
  }
  return total;
};
export const calculateRefundAmount = (
  onward,
  ret,
  onwardPax,
  returnPax,
  isReviewRefundPartial,
  props,
  state
) => {
  let totalDeductible = caculateTotalDeductible(
    onward,
    ret,
    onwardPax,
    returnPax,
    isReviewRefundPartial,
    props,
    state
  );
  let totalPaid = caculateTotalPaid(
    onward,
    ret,
    isReviewRefundPartial,
    props,
    state
  );
  return totalPaid - totalDeductible;
};

export const caculateTotalPaid = (
  paxOnwardRefundDetails,
  paxReturnRefundDetails,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  let condition1 = isReviewRefundPartial
    ? props.refundableDetails?.onwardJourney
    : props.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0;
  let condition2 = isReviewRefundPartial
    ? props.refundableDetails?.returnJourney
    : props.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0;
  if (condition1) {
    total += addingAmount(
      paxOnwardRefundDetails,
      'totalAmountPaid',
      isReviewRefundPartial ? props.onwardPaxID : state.onwardPaxIDs
    );
  }
  if (!props.isBookingAlreadyCancelled && paxOnwardRefundDetails) {
    if (
      paxOnwardRefundDetails[0]?.discountAvailed ||
      paxOnwardRefundDetails[0]?.smartSaverAmount
    ) {
      // total -= paxOnwardRefundDetails[0]?.discountAvailed;
      total += paxOnwardRefundDetails[0]?.smartSaverAmount;
    }
  }
  if (paxReturnRefundDetails?.length > 0)
    if (condition2)
      total += addingAmount(
        paxReturnRefundDetails,
        'totalAmountPaid',
        isReviewRefundPartial ? props.returnPaxID : state.returnPaxIDs
      );
  return total;
};
export const addingAmount = (array, keyToAdd, paxState) => {
  let total = 0;
  paxState.map((pax) => {
    if (array[pax.index][keyToAdd]) {
      const amount = array[pax.index][keyToAdd];
      if (typeof amount == 'string') total += +amount;
      else total += amount;
    }
  });
  return total;
};
// To be done
export const calculateMilesEanred = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  if (isReviewRefundPartial) {
    if (props.onwardPassengerList?.length > 0) {
      total += addingAmount(onward, 'milesEarned', props.onwardPaxID);
      total += addingAmount(onward, 'ancillariesMiles', props.onwardPaxID);

      if (!props.isUBR) {
        total += addingAmount(onward, 'tiMiles', props.onwardPaxID);
      } else {
        total += calculateInsuranceAmount(onward, ret, props);
      }
    }
    if (props.returnPaxDetails?.length > 0) {
      total += addingAmount(ret, 'milesEarned', props.returnPaxID);
      total += addingAmount(ret, 'ancillariesMiles', props.returnPaxID);
    }
  } else {
    if (
      props.onwardPassengerList?.length > 0 &&
      state.onwardPaxIDs.length > 0
    ) {
      total += addingAmount(onward, 'milesEarned', state.onwardPaxIDs);
      total += addingAmount(onward, 'ancillariesMiles', state.onwardPaxIDs);
      // adding TI amount/ combo Miles only once
      total += addingAmount(onward, 'tiMiles', state.onwardPaxIDs);
      // if (!this.props.isUBR)
      //     total += +onward[0]?.smartSaverMiles;
      total += +onward[0]?.bonusMiles;
      total += +onward[0]?.firstBonusMiles;
      total += +onward[0]?.tierBonusMiles;
    }
    if (props.returnPaxDetails?.length > 0) {
      total += addingAmount(ret, 'milesEarned', state.returnPaxIDs);
      total += addingAmount(ret, 'ancillariesMiles', state.returnPaxIDs);
    }
  }
  return total;
};

export const caculateIMFee = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  let condition1 = isReviewRefundPartial
    ? props.refundableDetails?.onwardJourney
    : props?.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0;
  let condition2 = isReviewRefundPartial
    ? props.refundableDetails?.returnJourney
    : props?.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0;
  if (condition1) {
    total += addingAmount(
      onward,
      'interMilesServiceFee',
      isReviewRefundPartial ? props.onwardPaxID : state.onwardPaxIDs
    );
  }
  if (ret?.length > 0) {
    if (condition2) {
      total += addingAmount(
        ret,
        'interMilesServiceFee',
        isReviewRefundPartial ? props.returnPaxID : state.returnPaxIDs
      );
    }
  }
  return total;
};

export const caculateIMConvenienceFee = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  let condition1 = isReviewRefundPartial
    ? props.refundableDetails?.onwardJourney
    : props.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0;
  let condition2 = isReviewRefundPartial
    ? props.refundableDetails?.returnJourney
    : props.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0;
  if (condition1) {
    total += addingAmount(
      onward,
      'imFee',
      isReviewRefundPartial ? props.onwardPaxID : state.onwardPaxIDs
    );
  }
  if (ret?.length > 0) {
    if (condition2) {
      total += addingAmount(
        ret,
        'imFee',
        isReviewRefundPartial ? props.returnPaxID : state.returnPaxIDs
      );
    }
  }
  return total;
};
// To be done
export const caculateAirLineFee = (
  onward,
  ret,
  onwardPax,
  returnPax,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  if (isReviewRefundPartial) {
    for (let index = 0; index < props.onwardPaxID.length; index++) {
      let element = props.onwardPaxID[index];
      if (
        ['NA', 'NF', 'null', null].includes(
          onward[element.index]['airlineCancellationFee']
        )
      ) {
        return 'NA';
      }
    }

    for (let index = 0; index < props.returnPaxID.length; index++) {
      let element = props.returnPaxID[index];
      if (
        ['NA', 'NF', 'null', null].includes(
          ret[element.index]['airlineCancellationFee']
        )
      ) {
        return 'NA';
      }
    }

    if (props.refundableDetails?.onwardJourney) {
      total += addingAmount(
        onward,
        'airlineCancellationFee',
        props.onwardPaxID
      );
    }
    if (ret?.length > 0) {
      if (props.refundableDetails?.returnJourney) {
        total += addingAmount(ret, 'airlineCancellationFee', props.returnPaxID);
      }
    }
  } else {
    let onward_ccg_check = onward?.find((ele) =>
      ['NA', 'NF', 'null', null].includes(ele.airlineCancellationFee)
    )
      ? true
      : false;
    let return_ccg_check = ret?.find((ele) =>
      ['NA', 'NF', 'null', null].includes(ele.airlineCancellationFee)
    )
      ? true
      : false;

    if (onward_ccg_check || return_ccg_check) {
      total = 'NA';
    } else {
      if (props.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0) {
        total += addingAmount(
          onward,
          'airlineCancellationFee',
          state.onwardPaxIDs
        );
      }
      if (ret?.length > 0) {
        if (props.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0) {
          total += addingAmount(
            ret,
            'airlineCancellationFee',
            state.returnPaxIDs
          );
        }
      }
    }
  }
  return total;
};
export const calculateAncillaries = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state
) => {
  let total = 0;
  let condition1 = isReviewRefundPartial
    ? props.onwardPaxID.length > 0
    : state.onwardPaxIDs.length > 0;
  let condition2 = isReviewRefundPartial
    ? props.returnPaxID.length > 0
    : state.returnPaxIDs.length > 0;
  if (condition1) {
    // total += onward[0].baseMiles
    total += addingAmount(
      onward,
      'ancillariesAmount',
      isReviewRefundPartial ? props.onwardPaxID : state.onwardPaxIDs
    );
    if (isReviewRefundPartial) {
      total += +onward[0]?.smartSaverAmount;
    } else {
      if (!props.isUBR) {
        total += +onward[0]?.smartSaverAmount;
      }
    }
  }
  if (ret?.length > 0)
    if (condition2)
      total += addingAmount(
        ret,
        'ancillariesAmount',
        isReviewRefundPartial ? props.returnPaxID : state.returnPaxIDs
      );

  // total += this.calculateInsuranceAmount(onward,ret).totalAmount;
  return total;
};

export const calculateInsuranceAmount = (onward, ret, props) => {
  let onwardPaxIDs = props.onwardPaxID;
  let returnPaxIDs = props.returnPaxID;
  let totalPax = 0;
  let totalAmount = 0;
  if (ret.length > 0) {
    onwardPaxIDs?.map((opax) => {
      returnPaxIDs?.map((rpax) => {
        if (opax.paxId == rpax.paxId) {
          // totalPax += 1;
          totalAmount += ret[rpax.index].tiMiles;
        }
      });
    });
    //pax cancelled from return
    onwardPaxIDs?.map((opax) => {
      let paxdetails = props.returnPaxArrayDetails.filter(
        (pax) => pax.paxId == opax.paxId
      );
      if (
        paxdetails.length > 0 &&
        paxdetails[0]['paxStatus'] == 'Cancel Requested'
      ) {
        totalAmount += ret[opax.index].tiMiles;
      }
    });
    // pax cancelled from onward
    returnPaxIDs?.map((opax) => {
      let paxdetails = props.onwardPaxArrayDetails.filter(
        (pax) => pax.paxId == opax.paxId
      );
      if (
        paxdetails.length > 0 &&
        paxdetails[0]['paxStatus'] == 'Cancel Requested'
      ) {
        totalAmount += ret[opax.index].tiMiles;
      }
    });
  } else {
    onwardPaxIDs.map((opax) => {
      totalPax += 1;
      totalAmount += onward[opax.index].tiMiles;
    });
  }
  return totalAmount;
};

export const calculatePaxLevelRedeemMiles = (
  onward,
  ret,
  isReviewRefundPartial,
  props,
  state,
  type
) => {
  let total = 0;
  let condition1 = isReviewRefundPartial
    ? props.refundableDetails?.onwardJourney
    : props.refundableDetails?.onwardJourney && state.onwardPaxIDs.length > 0;
  let condition2 = isReviewRefundPartial
    ? props.refundableDetails?.returnJourney
    : props.refundableDetails?.returnJourney && state.returnPaxIDs.length > 0;
  if (condition1) {
    total += addingAmount(
      onward,
      type,
      isReviewRefundPartial ? props.onwardPaxID : state.onwardPaxIDs
    );
  }
  if (ret?.length > 0) {
    if (condition2) {
      total += addingAmount(
        ret,
        type,
        isReviewRefundPartial ? props.returnPaxID : state.returnPaxIDs
      );
    }
  }
  return total;
};
