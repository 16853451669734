import config from '../config/config.json';
import { getCookieValue1, setSessionId } from './common';
import { checkKeyCloakSession } from './keycloak.service';
export async function checkSession() {
    if (config.isKeyCloakOn) {
        return checkKeyCloakSession();
    }
    else {
        const auth0Config = {
            "ConfigUrl": "https://www.intermiles.com",
            "auth0": {
                "clientID": config.auth0.clientID,
                "domain": config.auth0.domain,
                "callbackURL": window.location.origin + config.auth0.callbackURL,
                "audience": "",
                "portalLoginUrl": config.auth0.portalLoginUrl,
                "logoutReturnUrl": window.location.origin,
                "externalReturnUrl": window.location.origin,
                "scope": "openid profile",
                "ssofilepath": "",
                "responseType": "token id_token"
            },
            "HeaderFooterLink": config.HeaderFooterLink
        };

        const webauth0 = new auth0.WebAuth({
            clientID: auth0Config.auth0.clientID,
            domain: auth0Config.auth0.domain,
            responseType: auth0Config.auth0.responseType,
            audience: auth0Config.auth0.audience,
            redirectUri: auth0Config.auth0.callbackURL,
            scope: auth0Config.auth0.scope
        });

        // const auth0Authentication = new auth0.Authentication(webauth0, {
        //     domain: auth0Config.auth0.domain,
        //     clientID: auth0Config.auth0.clientID
        // });
        if (!getCookieValue1('JSESSION', 'jsessionid')) {
            await setSessionId(window)
        }

        return new Promise((resolve, reject) => {
            // logger("Inside Auth Service ===========================");  
            let token = null;
            let userDetailsInfo = {};
            //let isLoggedIn = false;
            webauth0.checkSession({
                responseType: 'id_token token',
                timeout: 10000,
                usePostMessage: true
            }, (err, authResult) => {
                // logger("====== User Auth Data ======", authResult);
                window['sessionID'] = getCookieValue1('JSESSION', 'jsessionid') ? getCookieValue1('JSESSION', 'jsessionid') : '';
                if (authResult && authResult.accessToken && authResult.idToken) {
                    token = authResult.idToken
                    let namespace = auth0Config.ConfigUrl;
                    let NameSpaceIdentifier = auth0Config.ConfigUrl + "/";
                    let OIDC = "";
                    let pointsBalance = "";
                    // logger("====== Auth Result idTokenPayload Before =======", authResult.idTokenPayload.user_id);
                    if (authResult.idTokenPayload[namespace + "/OIDC"] != undefined &&
                        authResult.idTokenPayload[namespace + "/OIDC"] === "ON") {
                        let idTokenPayload = JSON.stringify(authResult.idTokenPayload);
                        let regex = new RegExp(NameSpaceIdentifier, "g");
                        authResult.idTokenPayload = JSON.parse(idTokenPayload.replace(regex, ""));
                        OIDC = "ON";
                        pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalance;
                        authResult.idTokenPayload.cris_Member_PointsBalanace = authResult.idTokenPayload.cris_Member_PointsBalance;
                    } else {
                        OIDC = "OFF";
                        pointsBalance = authResult.idTokenPayload.cris_Member_PointsBalanace;
                    }
                    // logger("====== Auth Result idTokenPayload After =======", authResult.idTokenPayload.user_id);

                    userDetailsInfo = authResult.idTokenPayload;
                    //isLoggedIn = true;
                    window['UD'] =
                    {
                        "JPNumber": authResult.idTokenPayload.user_id.replace("auth0|00", ""),
                        "name": authResult.idTokenPayload.cris_Member_FirstName,
                        "PointsBalanace": pointsBalance,
                        "tier": authResult.idTokenPayload.cris_Member_Tier,
                        "EmailVerified": authResult.idTokenPayload.cris_Member_IsEmailVerified,
                        "MobileVerified": authResult.idTokenPayload.cris_Member_IsMobileVerified,
                        "PersonId": authResult.idTokenPayload.cris_Member_PersonID,
                        "activeStatus": authResult.idTokenPayload.cris_Member_ActiveStatus,
                        "age": "uSdKMmg8GbYjn6v/l43giQ#XXX##XXX#3d0501a563e4", //Encryptred value
                        "cityofResidence": "700IPHr0vRsTZAs4fP+Zxw#XXX##XXX#3d0501a563e4", //Encryptred value
                        "countryofResidence": "8kUut5dL2Ptwns5+6wL9wQ#XXX##XXX#3d0501a563e4", //Encryptred value
                        "gender": "e0lmJRyXv5xxF45vfNPChw#XXX##XXX#3d0501a563e4", //Encryptred value
                        "firstloginDate": authResult.idTokenPayload.created_at,
                        "lastloginDate": authResult.idTokenPayload.cris_Member_LastLoginDate,
                        "memProgram": authResult.idTokenPayload.cris_Member_MemProgram,
                        "LoggedInUsing": "JPNO"
                    };
                    window['JPNumber'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
                    window['JPNo'] = authResult.idTokenPayload.user_id.replace("auth0|00", "")
                    window['OIDC'] = OIDC;
                    localStorage.setItem("fs", authResult.idTokenPayload.created_at);
                    localStorage.setItem("ls", authResult.idTokenPayload.cris_Member_LastLoginDate);

                    let loggedIn_Info = {};
                    if (userDetailsInfo && userDetailsInfo.nickname) {
                        loggedIn_Info = {
                            member_number: userDetailsInfo.nickname,
                            tier: userDetailsInfo["cris_Member_Tier"],
                            isLoggedIn: true,
                            email: userDetailsInfo["cris_Member_EmailAddress"],
                            first_name: userDetailsInfo["cris_Member_FirstName"],
                            last_name: userDetailsInfo["cris_Member_LastName"],
                            mobile_number: userDetailsInfo["cris_Member_MobileNumber"],
                            member_title: userDetailsInfo["cris_Member_Title"],
                            points_balance: userDetailsInfo["cris_Member_PointsBalance"],
                            gender: userDetailsInfo["cris_Member_Gender"],
                            country: userDetailsInfo["cris_Member_CountryOfResidence"],
                            at_hash: userDetailsInfo["at_hash"] || '',
                            PID: userDetailsInfo["cris_Member_PersonID"],
                            token: token
                        }
                    } else {
                        loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
                    }

                    resolve(loggedIn_Info);
                } else if (err) {
                    // logger("Error =======>", err);
                    //isLoggedIn = false;
                    userDetailsInfo = undefined;
                    if (err.error === "Unauthorized" && err.error_description === "Log user out due to Auth Time expiry") {
                        //window.auth0LogoutMS1();
                        window["MvpHeader"].auth0LogoutMS1();
                    }
                    sessionStorage.removeItem("membername");
                    sessionStorage.removeItem("jpnumber");
                    let loggedIn_Info = { member_number: "", tier: "", isLoggedIn: false };
                    resolve(loggedIn_Info)
                }
            })
        })
    }
}
