import config from '../config/config.json';

export const generateVia = (legArray, joinByTxt, appendViaTxt) => {
    if (legArray && legArray.length > 0) {
        const viaResult = legArray.map((currLeg) => { return currLeg.ori });
        viaResult.push(legArray[legArray.length - 1].des);
        let textToAppend = appendViaTxt == true ? "via " : "";
        return (textToAppend + viaResult.join(joinByTxt))
    }
}
export const generateOriDesText=(legArray)=>{
    if (legArray && legArray.length>0) {
        return legArray[0].ori+" - "+legArray[legArray.length-1].des;
    }
}

export const calculateDayDiff = (leg, appendDayText) => {
    if (leg && leg.length> 0) {
        var date1 = new Date(leg[0].ddt);
        var date2 = new Date(leg[leg.length - 1].adt);
    
        // To calculate the time difference of two dates 
    // To calculate the time difference of two dates 
        // To calculate the time difference of two dates 
    // To calculate the time difference of two dates 
        // To calculate the time difference of two dates 
        var Difference_In_Time = date2.getTime() - date1.getTime();
    
        // To calculate the no. of days between two dates 
    // To calculate the no. of days between two dates 
        // To calculate the no. of days between two dates 
    // To calculate the no. of days between two dates 
        // To calculate the no. of days between two dates 
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        const dayText = appendDayText == true ? 'day' : '';
        const day = Difference_In_Days > 0 ? `+  ${Difference_In_Days} ${dayText}` : null;
        return day;
    }


}

export const logger = (message) => {
  if (config.isConsole) {
    return console.log(message);
  } else {
    return;
  }
};