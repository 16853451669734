import dateFormat from 'dateformat';

function getDeviceType(isMobile, page = '') {
    // Check if it's a mobile device based on the user agent
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);

    // Determine the type based on isMobile and page
    if (isMobile) {
        if (page === 'SRP') {
            return 'imapp';
        }
        return 'imwebview';
    } else if (isMobileDevice) {
        return 'immob';
    } else {
        return 'imweb';
    }
}

export const Adobe = {
    pageLoad: function (title, page, pageInfo, userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";

            window.digitalData = {
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile, page),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    pid: getCookieValue("JetPrivilege_CCounter_C", "PID"),
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    pointsBalance: userInfo.isLoggedIn ? userInfo.points_balance : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                    sessionID: this.getCookieValue1('JSESSION', 'jsessionid'),
                    sessionDateTime: this.getCookieValue1('JSESSION', 'jsessiontime')
                }

            }
            _satellite.track(title);
        } catch (e) {
            this.errorTracking({errorDescription:e,errorCode:''},page,pageInfo, userInfo)
        }
    },

    onPageLoadSRP : function (title, page, pageInfo, userInfo, sortObject){
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile,page),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    pid: getCookieValue("JetPrivilege_CCounter_C", "PID"),
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    pointsBalance: userInfo.isLoggedIn ? userInfo.points_balance : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                    sessionID: this.getCookieValue1('JSESSION', 'jsessionid'),
                    sessionDateTime: this.getCookieValue1('JSESSION', 'jsessiontime')
                },
                sortValue: sortObject.sortValue,
                filterUsed: sortObject.filterUsed,
                noOfResults: sortObject.noOfResults

            }
            _satellite.track(title);

        } catch (e) {
            
            this.errorTracking({errorDescription:e,errorCode:''},'SRP',pageInfo, userInfo)
        }
    },

    // getCookieValue1(cname, ckey) {
    //     if (typeof ($.cookie(cname)) !== 'undefined') {
    //         let valuearray = unescape($.cookie(cname)).split('&');
    //         for (let i = 0; i < valuearray.length; i++) {
    //             if (valuearray[i].indexOf(ckey) >= 0) {
    //                 let keyval = valuearray[i].split('=');
    //                 if (keyval.length > 1) {
    //                     return keyval[1];
    //                 }
    //             }
    //         }
    //     }

    //     return "";
    // },

    getCookieValue1(cname, ckey) {
        if (typeof ($.cookie(cname)) !== 'undefined') {
            var valuearray = unescape($.cookie(cname)).split('&');
            for (var i = 0; i < valuearray.length; i++) {
                if (valuearray[i].indexOf(ckey) >= 0) {
                    var keyval = valuearray[i].split('=');
                    if (keyval.length > 1) {
                        return keyval[1];
                    }
                }
            }
        }
        return "";
    },

    clickEvent(eventName,page,pageInfo,userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo: {
                    eventName: eventName,
                    eventType: 'click',
                },
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                }
            }
            _satellite.track('eventTracking');
        } catch (e) {
            
            this.errorTracking({errorDescription:e,errorCode:''},page,pageInfo,userInfo)
        }
    },

    modifySearch: function (modifysearchObj) {

        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo: {
                    eventName: "search click"
                },
                pageInfo: {
                    category: modifysearchObj.pageInfo.category,
                    pageName: modifysearchObj.pageInfo.pageName,
                    subCategory1: modifysearchObj.pageInfo.subCategory1,
                    subCategory2: modifysearchObj.pageInfo.subCategory2,
                    subCategory3: modifysearchObj.pageInfo.subCategory3,
                    milesCategory: modifysearchObj.pageInfo.milesCategory,
                    property: modifysearchObj.pageInfo.property,
                    pageSource: getDeviceType(isMobile,page),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: modifysearchObj.loggedIn_Info.isLoggedIn ? modifysearchObj.loggedIn_Info.member_number.substring(2) : '',
                    tier: modifysearchObj.loggedIn_Info.isLoggedIn ? modifysearchObj.loggedIn_Info.tier : '',
                    pointsBalance: modifysearchObj.loggedIn_Info.isLoggedIn ? modifysearchObj.loggedIn_Info.points_balance : '',
                    loginStatus: modifysearchObj.loggedIn_Info.isLoggedIn ? 'LOGGED-IN' : 'ANONYMOUS'
                },
                searchInfo: {
                    departureDate: modifysearchObj.departureDate ? dateFormat(modifysearchObj.departureDate,"dd-mmm-yyyy") : '',
                    arrivalDate: modifysearchObj.arrivalDate ? dateFormat(modifysearchObj.arrivalDate,"dd-mmm-yyyy") : '',
                    travelDestination: modifysearchObj.travelDestination,
                    travelOrigin: modifysearchObj.travelOrigin,
                    pax: modifysearchObj.pax,
                    cabinType: modifysearchObj.cabinType,
                    journeyType: modifysearchObj.journeyType,
                    flightType: modifysearchObj.flightType,
                    totalTravellers: modifysearchObj.totalTravellers
                },
                searchinfo : {
                    searchResultType: modifysearchObj.searchResultType,
                },
                labelId: modifysearchObj.labelId,

            }
            _satellite.track('flightSearchClick');
        } catch (e) {
            this.errorTracking({errorDescription:e,errorCode:''},'SRP',modifysearchObj.pageInfo,modifysearchObj.loggedIn_Info)
        }
    },

    onClickBookNowSRP : function(responseObject){
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo : {
                    eventName : "Book Now"
                },
                pageInfo: {
                    category: responseObject.pageInfo.category,
                    pageName: responseObject.pageInfo.pageName,
                    subCategory1: responseObject.pageInfo.subCategory1,
                    subCategory2: responseObject.pageInfo.subCategory2,
                    subCategory3: responseObject.pageInfo.subCategory3,
                    milesCategory: responseObject.pageInfo.milesCategory,
                    property: responseObject.pageInfo.property,
                    pageSource: getDeviceType(isMobile,page),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: responseObject.loggedIn_Info.isLoggedIn ? responseObject.loggedIn_Info.member_number.substring(2) : '',
                    tier: responseObject.loggedIn_Info.isLoggedIn ? responseObject.loggedIn_Info.tier : '',
                    pointsBalance: responseObject.loggedIn_Info.isLoggedIn ? responseObject.loggedIn_Info.points_balance : '',
                    loginStatus: responseObject.loggedIn_Info.isLoggedIn ? 'LOGGED-IN' : 'ANONYMOUS'
                },
                searchInfo : {
                    departureDate : responseObject.departureDate ? dateFormat(responseObject.departureDate,"dd-mmm-yyyy") : '',
                    arrivalDate : responseObject.arrivalDate ? dateFormat(responseObject.arrivalDate,"dd-mmm-yyyy") : '',
                    travelDestination : responseObject.travelDestination,
                    travelOrigin : responseObject.travelOrigin,
                    pax : responseObject.pax,
                    cabinType : responseObject.cabinType,
                    journeyType : responseObject.journeyType,
                    flightType : responseObject.flightType,
                    totalTravellers : parseInt(responseObject.totalTravellers)
                },
                searchinfo : {
                    searchResultType : sessionStorage.getItem('home') == 'true' ? 'original' : 'modify'
                },
                flightsInfo : {
                    onwardsAirlinePartner : responseObject.onwardsAirlinePartner,
                    onwardsFlightNo : responseObject.onwardsFlightNo,
                    onwardsSeatLeft : responseObject.onwardsSeatLeft,
                    returnAirlinePartner : responseObject.returnAirlinePartner,
                    returnFlightNo : responseObject.returnFlightNo,
                    returndsSeatLeft : responseObject.returndsSeatLeft,
                },
                flightInfo : {
                    fareType : responseObject.fareType
                },
                sortValue : responseObject.sortValue,
                filterUsed : responseObject.filterUsed,

                productInfo : {
                    productName : responseObject.productName,
                    productQty : responseObject.productQty
                },
                paymentType : responseObject.paymentType
            }
            _satellite.track('flightBookNow');

        } catch (e){
            this.errorTracking({errorDescription:e,errorCode:''},'SRP',responseObject.pageInfo,responseObject.loggedIn_Info)
        }
    },

    pageLoadBCP: function (req,pageInfo, userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            if(req.bookingStatus == 'Fail'){
                window.digitalData = {
                    eventInfo: {
                        eventName: req.eventInfo.eventName,
                        airAncillariesSelected: req.eventInfo.airAncillariesSelected,
                        nonAirAncillariesSelected: req.eventInfo.non_airAncillariesSelected
                    },
                    bookingStatus:req.bookingStatus,
                    searchInfo: {
                        departureDate: req.searchInfo.departureDate ? dateFormat(req.searchInfo.departureDate,"dd-mmm-yyyy") : '',
                        arrivalDate: req.searchInfo.arrivalDate ? dateFormat(req.searchInfo.arrivalDate,"dd-mmm-yyyy") : '',
                        travelDestination: req.searchInfo.travelDestination,
                        travelOrigin: req.searchInfo.travelOrigin,
                        pax: req.searchInfo.pax,
                        cabinType: req.searchInfo.cabinType,
                        journeyType: req.searchInfo.journeyType ? req.searchInfo.journeyType == 'ONEWAY' ? 'ONE WAY' : 'RETURN' : '',
                        flightType: req.searchInfo.flightType,
                        totalTravellers: req.searchInfo.totalTravellers
                    },
                    flightsInfo: {
                        onwardsAirlinePartner: req.flightsInfo.onwardsAirlinePartner,
                        onwardsFlightNo: req.flightsInfo.onwardsFlightNo,
                        returnAirlinePartner: req.flightsInfo.returnAirlinePartner,
                        returnFlightNo: req.flightsInfo.returnFlightNo
                    },
                    productInfo: {
                        productName: req.productInfo.productName,
                        productQty: req.productInfo.productQty
                    },
                    totalAmount:req.totalAmount,
                    milesEarned:req.milesEarned,
                    milesRedeemed:req.milesRedeemed,
                    offer: {
                        offertitle:req.offer.offertitle,
                        offerDescription:req.offer.offerDescription,
    
                    },
                    promoCode: req.promoCode,
                    flightInfo:{
                        fareType:req.flightInfo.fareType,
                    },
                    paymentType:req.paymentType,
                    transactionID:req.transactionID,
                    partnerName:req.partnerName,
                    errorInfo: {
                        errorDescription : "Failed Booking received from supplier"
                    },
    
                    pageInfo: {
                        category: pageInfo.category,
                        pageName: pageInfo.pageName,
                        subCategory1: pageInfo.subCategory1,
                        subCategory2: pageInfo.subCategory2,
                        subCategory3: pageInfo.subCategory3,
                        milesCategory: pageInfo.milesCategory,
                        property: pageInfo.property,
                        pageSource: getDeviceType(isMobile),
                        platform: getDeviceType(isMobile),
                        isWebView: isMobile ? 'Y' : 'N'
                    },
                    userInfo: {
                        JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2): '',
                        tier: userInfo.isLoggedIn ? userInfo.tier : '',
                        loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                    }
    
                }
            } 
            else {
                window.digitalData = {
                    eventInfo: {
                        eventName: req.eventInfo.eventName,
                        airAncillariesSelected: req.eventInfo.airAncillariesSelected,
                        nonAirAncillariesSelected: req.eventInfo.non_airAncillariesSelected
                    },
                    bookingStatus:req.bookingStatus,
                    searchInfo: {
                        departureDate: req.searchInfo.departureDate ? dateFormat(req.searchInfo.departureDate,"dd-mmm-yyyy") : '',
                        arrivalDate: req.searchInfo.arrivalDate ? dateFormat(req.searchInfo.arrivalDate,"dd-mmm-yyyy") : '',
                        travelDestination: req.searchInfo.travelDestination,
                        travelOrigin: req.searchInfo.travelOrigin,
                        pax: req.searchInfo.pax,
                        cabinType: req.searchInfo.cabinType,
                        journeyType: req.searchInfo.journeyType ? req.searchInfo.journeyType == 'ONEWAY' ? 'ONE WAY' : 'RETURN' : '',
                        flightType: req.searchInfo.flightType,
                        totalTravellers: req.searchInfo.totalTravellers
                    },
                    flightsInfo: {
                        onwardsAirlinePartner: req.flightsInfo.onwardsAirlinePartner,
                        onwardsFlightNo: req.flightsInfo.onwardsFlightNo,
                        returnAirlinePartner: req.flightsInfo.returnAirlinePartner,
                        returnFlightNo: req.flightsInfo.returnFlightNo
                    },
                    productInfo: {
                        productName: req.productInfo.productName,
                        productQty: req.productInfo.productQty
                    },
                    totalAmount:req.totalAmount,
                    milesEarned:req.milesEarned,
                    milesRedeemed:req.milesRedeemed,
                    offer: {
                        offertitle:req.offer.offertitle,
                        offerDescription:req.offer.offerDescription,
    
                    },
                    promoCode: req.promoCode,
                    flightInfo:{
                        fareType:req.flightInfo.fareType,
                    },
                    paymentType:req.paymentType,
                    transactionID:req.transactionID,
                    partnerName:req.partnerName,
    
                    pageInfo: {
                        category: pageInfo.category,
                        pageName: pageInfo.pageName,
                        subCategory1: pageInfo.subCategory1,
                        subCategory2: pageInfo.subCategory2,
                        subCategory3: pageInfo.subCategory3,
                        milesCategory: pageInfo.milesCategory,
                        property: pageInfo.property,
                        pageSource: getDeviceType(isMobile),
                        platform: getDeviceType(isMobile),
                        isWebView: isMobile ? 'Y' : 'N'
                    },
                    userInfo: {
                        JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2): '',
                        tier: userInfo.isLoggedIn ? userInfo.tier : '',
                        loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                    }
                }
            }
            _satellite.track('flightBookingConfirmation');
        } catch (e) {
            
            this.errorTracking({errorDescription:e,errorCode:''},'BCP',pageInfo, userInfo)
        }},

    continueToPaymentBRP: function (req, pageInfo, userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo: {
                    eventName: req.eventInfo.eventName,
                    airAncillariesSelected: req.eventInfo.airAncillariesSelected,
                    nonAirAncillariesSelected: req.eventInfo.nonairAncillariesSelected
                },
                searchInfo: {
                    departureDate: req.searchInfo.departureDate ? dateFormat(req.searchInfo.departureDate,"dd-mmm-yyyy") : '',
                    arrivalDate: req.searchInfo.arrivalDate ? dateFormat(req.searchInfo.arrivalDate,"dd-mmm-yyyy") : '',
                    travelDestination: req.searchInfo.travelDestination,
                    travelOrigin: req.searchInfo.travelOrigin,
                    pax: req.searchInfo.pax,
                    cabinType: req.searchInfo.cabinType,
                    journeyType: req.searchInfo.journeyType,
                    flightType: req.searchInfo.flightType,
                    totalTravellers: req.searchInfo.totalTravellers
                },
                flightsInfo: {
                    onwardsAirlinePartner: req.flightsInfo.onwardsAirlinePartner,
                    onwardsFlightNo: req.flightsInfo.onwardsFlightNo,
                    returnAirlinePartner: req.flightsInfo.returnAirlinePartner,
                    returnFlightNo: req.flightsInfo.returnFlightNo
                },
                productInfo: {
                    productName: req.productInfo.productName,
                    productQty: req.productInfo.productQty
                },
                flightInfo: {
                    fareType: req.flightInfo.fareType,
                    
                },
                paymentType: req.paymentType,
                partnerName: req.partnerName,
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                }
                
            }
            _satellite.track('flightPayment');
        } catch (e) {
            this.errorTracking({errorDescription:e,errorCode:''},'BRP',pageInfo, userInfo)
        }
    },
    errorTracking:function(error,page,pageInfo,userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo: {
                    eventName: "error"
                },
                errorInfo:{
                    errorDescription: error.errorDescription,
                    errorCode:error.errorCode
                },
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile, page),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                }
            }
            _satellite.track('errorTracking');
        } catch (e) {
        }
    },
    offersClickHomepageSRP: function (req, pageInfo, userInfo) {
        try {
            let isMobile = window.navigator.userAgent === "IM-Mobile-App";
            window.digitalData = {
                eventInfo: {
                    eventName: req.eventInfo.eventName,
                },
                tabName: req.tabName,

                brickInfo: {
                    brickType: req.brickInfo.brickType,
                    partnerName: req.brickInfo.partnerName,
                    partnerCode: req.brickInfo.partnerCode,
                    offersDisplayed: req.brickInfo.offersDisplayed,
                    offersExpire: req.brickInfo.offersExpire ? dateFormat(req.brickInfo.offersExpire,"dd-mmm-yyyy") : '',
                    offersPosition: req.brickInfo.offersPosition
                },
                offerInfo:{
                    offerName:req.offerInfo.offerName
                },
                promoCode: req.promoCode,
                pageInfo: {
                    category: pageInfo.category,
                    pageName: pageInfo.pageName,
                    subCategory1: pageInfo.subCategory1,
                    subCategory2: pageInfo.subCategory2,
                    subCategory3: pageInfo.subCategory3,
                    milesCategory: pageInfo.milesCategory,
                    property: pageInfo.property,
                    pageSource: getDeviceType(isMobile),
                    platform: getDeviceType(isMobile),
                    isWebView: isMobile ? 'Y' : 'N'
                },
                userInfo: {
                    JPNumber: userInfo.isLoggedIn ? userInfo.member_number.substring(2) : '',
                    tier: userInfo.isLoggedIn ? userInfo.tier : '',
                    loginStatus: userInfo.isLoggedIn ? "LOGGED-IN" : "ANONYMOUS",
                }
                
            }
            _satellite.track('offerClicked');
        } catch (e) {
            this.errorTracking({errorDescription:e,errorCode:''},'home',pageInfo, userInfo)
        }
    },

};