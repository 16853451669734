import dateFormat from 'dateformat';
import { logger } from '../services/commonFunction';

export const CTEvent = {
    pushEvent: function (event_name) {
        try {
            // logger('Call CT event hit here=====>>', event_name)
            var clevertap_data = { ...window.clevertapData[event_name], ...window.clevertapData["Global"] };
            clevertap_data['CleverTap ID'] = window.clevertap.getCleverTapID();

            for (var key in clevertap_data) {
                if (clevertap_data[key] === null || clevertap_data[key] === undefined || clevertap_data[key] === "") delete clevertap_data[key];
            }

            // logger("clevertap data:", clevertap_data);
            window.clevertap.event.push(event_name, clevertap_data);
        } catch (e) {
            //logger("ct err:", e);
            this.callErrEvt(event_name, e);
        }
    },
    setGlobal: function () {
        try {
            // logger("window['JPNumber']:", window['JPNumber']);
            if (window.clevertapData == undefined) window.clevertapData = {};
            if (window.clevertapData["Global"] == undefined) window.clevertapData["Global"] = {};
            if (window.clevertapData["Others"] == undefined) window.clevertapData["Others"] = {};
            window.clevertapData["Global"]['Channel'] = (window.navigator.userAgent === "IM-Mobile-App") ? 'Webview' : (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) ? 'Mweb' : 'Web';//'<App/Web/Webview/Mweb>', 
            window.clevertapData["Global"]['Property'] = 'Flights';
            window.clevertapData["Global"]['Category'] = window.location.href.includes("redeem-airmiles") ? "Redeem" : window.location.href.includes("earn-airmiles") ? 'Earn' : 'None';//<Earn/Redeem/None>
            window.clevertapData["Global"]['Page Name'] = (window.location.host + ((window.location.pathname == "/") ? "/home" : window.location.pathname)).replace(/\//g, ":");
            //   'CleverTap ID': window.clevertap.getCleverTapID(),
            window.clevertapData["Global"]['Login Status'] = window?.obj_HeaderFooterUserInfo?.loginStatus ===  "LOGGED-IN" ? 'Logged In' : "Anonymous";
        } catch (e) {
            //logger("ct err:", e);
            this.callErrEvt("Global", e);
        }
    },
    callSearch: function (d) {

        try {
            window["clevertapData"]["Global"]['Category'] = d.cat;
            window["clevertapData"]["Search"] = {
                'Action': d.action,
                'Flight Origin Code': d.ocode,
                'Flight Destination Code': d.dcode,
                'Flight Origin Description': d.odesc,
                'Origin Destination': d.ocode + "-" + d.dcode,
                'Flight Destination Description': d.ddesc,
                'Flight Origin Airport': d.oairport,
                'Flight Destination Airport': d.dairport,
                'Flight Return Date': d.rdate,
                'Flight Onward Date': d.odate,
                'Flight Booking Class': d.class,
                'Flight Journey Type': d.jtype,
                'Flight Type': d.ftype,
                'Flight Search Type Origin': d.ostype,
                'Flight Search Type Destination': d.dstype,
                'Flight Travellers Adult': d.adult,
                'Flight Travellers Child': d.child,
                'Flight Travellers Infant': d.infant,
                'Total Travellers': parseInt(d.adult) + parseInt(d.child) + parseInt(d.infant)
            };
            this.pushEvent("Search");
        } catch (e) {
            //logger("ct err:", e);
            this.callErrEvt("Search", e);
        }
    },
    callErrEvt: function (err_scenario, err_msg) {
        try {
            window["clevertapData"]["Error"] = {
                "Action": "Error",
                "Error Scenario": err_scenario,
                "Error Message": err_msg
            };
            setTimeout(() => {
                this.pushEvent("Error");
            }, 3000);
        } catch (e) {
            logger("ct err:", e)
        }
    },
    callActionEvt: function (action_value) {
        try {
            window.clevertapData['Action Click'] = {
                "Action": action_value
            };
            this.pushEvent("Action Click");
        } catch (e) {
            this.callErrEvt("Action Click", e);
            //logger("ct err:", e)
        }
    },
    callActionEvtHome: function (action_value) {
        try {
            window.clevertapData['Action Click'] = {
                "Action": action_value,
                "App": window.navigator.userAgent === "IM-Mobile-App" ? 'Y' : 'N',
                "Web": window.navigator.userAgent != "IM-Mobile-App" ? 'Y' : 'N' 
            };
            logger(window.clevertapData['Action Click'] )
            this.pushEvent("Action Click");
        } catch (e) {
            this.callErrEvt("Action Click", e);
            //logger("ct err:", e)
        }
    },
    formatCTFilters: function (filters) {
        var filter_str = "";
        try {
            if (filters.AIRLINES && filters.AIRLINES.length > 0) filter_str += "Airline:" + filters.AIRLINES.toString() + ";";
            if (filters.BAGGAGE === true) filter_str += "Baggage:" + "Y" + ";";
            if (filters.ONWARDDEPT.length > 0) filter_str += "Departure:" + filters.ONWARDDEPT.toString() + ";";
            if (filters.REFUND === true) filter_str += "Refundable:" + "Y" + ";";
            if (filters.STOPS && filters.STOPS.length > 0) filter_str += "Stops:" + filters.STOPS.toString() + ";";
            if (filters.ONWARDPRICE && filters.ONWARDPRICE.lowestPrice) filter_str += "PriceMin:" + filters.ONWARDPRICE.lowestPrice + ";";
            if (filters.ONWARDPRICE && filters.ONWARDPRICE.highestPrice) filter_str += "PriceMax:" + filters.ONWARDPRICE.highestPrice + ";";
            //ubr
            if (filters.ONWARDAIRLINES && filters.ONWARDAIRLINES.length > 0) filter_str += "Airline:" + filters.ONWARDAIRLINES.toString() + ";";
            if (filters.ONWARDSTOPS && filters.ONWARDSTOPS.length > 0) filter_str += "Stops:" + filters.ONWARDSTOPS.toString() + ";";
        } catch (e) {
            logger("ct err:", e)
        }
        return filter_str.slice(0, -1);
    },
    formatCTFiltersReturn: function (filters) {
        var filter_str = "";
        try {
            if (filters.AIRLINES_RETURN && filters.AIRLINES_RETURN.length > 0) filter_str += "Airline:" + filters.AIRLINES_RETURN.toString() + ";";
            if (filters.BAGGAGE_RETURN === true) filter_str += "Baggage:" + "Y" + ";";
            if (filters.RETURNDEPT && filters.RETURNDEPT.length > 0) filter_str += "Departure:" + filters.RETURNDEPT.toString() + ";";
            if (filters.REFUND_RETURN === true) filter_str += "Refundable:" + "Y" + ";";
            if (filters.STOPS_RETURN && filters.STOPS_RETURN.length > 0) filter_str += "Stops:" + filters.STOPS_RETURN.toString() + ";";
            if (filters.RETURNPRICE && filters.RETURNPRICE.lowestPrice) filter_str += "PriceMin:" + filters.RETURNPRICE.lowestPrice + ";";
            if (filters.RETURNPRICE && filters.RETURNPRICE.highestPrice) filter_str += "PriceMax:" + filters.RETURNPRICE.highestPrice + ";";
            //ubr
            if (filters.RETURNAIRLINES && filters.RETURNAIRLINES.length > 0) filter_str += "Airline:" + filters.RETURNAIRLINES.toString() + ";";
            if (filters.RETURNSTOPS && filters.RETURNSTOPS.length > 0) filter_str += "Stops:" + filters.RETURNSTOPS.toString() + ";";
        } catch (e) {
            logger("ct err:", e)
        }
        return filter_str.slice(0, -1);
    },
    formatCTSort: function (sortByEnabledValue, sortingisAscending) {
        try {
            return sortByEnabledValue.charAt(0).toUpperCase() + sortByEnabledValue.slice(1) + (sortByEnabledValue == "" ? "" : ":" + (sortingisAscending == "ASC" ? "A-D" : "D-A"));
        } catch (e) {
            logger("ct err:", e)
            return "";
        }
    },
    getDate: function (type) {
        const qString = window.location.search
        const urlParam = new URLSearchParams(qString)
        if (type == 'onw') return urlParam.get('onwarddt')
        if (type == 'ret') return urlParam.get('returndt')
    },
    formatDate: function (datestr) {
        return require("moment")(datestr, 'ddd-DDMMMYYYY').format('YYYY-MM-DD');//'Sat-06Feb2021'
    },
    addToCart: function (cat, d, dUrl) {
        try {
            let is_filter_sort_present = window.clevertapData ?.Others ?.filtersort != undefined;
            let oneway = d.data.Ttype == 'One Way', round = d.data.Ttype != 'One Way', rdm = window.clevertapData["Global"]['Category'] == "Redeem",
                intl = d.data.oth ? d.data.oth.IsInternational : d.state.onWardFlightDetails.oth.IsInternational,
                oleg = d.data.Ttype == 'One Way' || intl ? d.data.leg : d.state.onWardFlightDetails.leg,
                rleg = round ? intl ? d.data.legr : d.state.returnFlightDetails.leg : {};
            // logger("Details >>>>>", d, ">", cat, dUrl, is_filter_sort_present);
            // window["clevertapData"]["Global"]['Category'] = cat;
            let noOfAdults = oneway || intl ? d.data.noOfAdults : d.state.onWardFlightDetails.noOfAdults;
            let noOfChilds = oneway || intl ? d.data.noOfChilds : d.state.onWardFlightDetails.noOfChilds;
            let noOfInfants = oneway || intl ? d.data.noOfInfants : d.state.onWardFlightDetails.noOfInfants;

            window["clevertapData"]["Add to Cart"] = {
                "Action": "Book Now",
                "Destination URL": window.location.origin + dUrl,
                "Amount": !rdm ? (oneway || intl) ? Number(d.state.flt_price) : Number(d.data.Price.split(',')[0] + d.data.Price.split(',')[1]) : "",
                "Miles Earned": !rdm ? intl ? d.data.JPMiles : oneway ? d.state.adultmiles * d.data.traveller : (d.state.JPMilesOnward + d.state.JPMilesReturn) * d.data.traveller : "",
                "Miles Redeemed": !rdm ? "" : oneway || intl ? d.data.finalmaxmiles * d.data.traveller : ((d.state.onWardFlightDetails.finalmaxmiles * d.state.onWardFlightDetails.traveller) + (d.state.returnFlightDetails.finalmaxmiles * d.state.returnFlightDetails.traveller)),
                "Flight Origin Code": oleg[0].ori,
                "Flight Destination Code": oleg[oleg.length - 1].des,
                "Origin Destination": oleg[0].ori + '-' + oleg[oleg.length - 1].des, 
                "Flight Origin Description": oleg[0].octy,
                "Flight Destination Description": oleg[oleg.length - 1].dcty,
                "Flight Origin Airport": oleg[0].ate,
                "Flight Destination Airport": oleg[oleg.length - 1].dte,
                "Flight Return Date": d.data.Ttype !== "One Way" ? new Date(this.getDate('ret')) : "",
                "Flight Onward Date": new Date(this.getDate('onw')),
                "Flight Booking Class": oleg[0].fty,
                "Flight Journey Type": d.data.Ttype,
                "Flight Type": intl ? "International" : "Domestic",
                "Flight Travellers Adult": noOfAdults,
                "Flight Travellers Child": noOfChilds,
                "Flight Travellers Infant": noOfInfants,
                "Total Travellers": parseInt(noOfAdults) + parseInt(noOfChilds) + parseInt(noOfInfants),
                "Flight Onward Airline Partner": oleg[0].crn,
                "Flight Onward Number Of Stops": oleg.length - 1 == 0 ? "non-stop" : oleg.length - 1 + "-stop",
                "Flight Onward Airline Number": Number(oleg[0].fnum),
                "Flight Onward Arrival Time": new Date(this.formatDate(oleg[oleg.length - 1].adt) + " " + oleg[oleg.length - 1].ati),
                "Flight Onward Departure Time": new Date(this.getDate('onw') + " " + oleg[0].dti),
                "Flight Onward Seat Left": oleg[0].seat,
                "Flight Onward Refundable": oneway || intl ? (d.data.Refundable ? 'Y' : 'N') : d.state.onWardFlightDetails.Refundable ? "Y" : "N",
                "Flight Onward Miles offered": cat === 'Earn' && (oneway || intl) ? d.state.adultmiles : d.state.onwardFlexiDetail ? d.state.onwardFlexiDetail.JPMiles : d.state.JPMilesOnward,
                "Flight Onward Fare Type": oneway ? cat == "Earn" ? (d.state.selectedFlexiObj ?.flexiBenefitDetails ?.CATEGORY_NAME || "Saver") : "Saver" : (d.state.onwardFlexiDetail ?.flexiBenefitDetails ?.CATEGORY_NAME || "Saver"),
                "Flight Onward Miles Redeemed": cat !== 'Earn' ? oneway ? d.data.finalmaxmiles * d.data.traveller : d.state.onWardFlightDetails.finalmaxmiles * d.state.onWardFlightDetails.traveller : "",
                "Flight Return Miles Redeemed": cat !== 'Earn' && d.data.Ttype !== 'One Way' ? d.state.returnFlightDetails.finalmaxmiles * d.state.returnFlightDetails.traveller : "",
                "Flight Return Airline Partner": oneway ? "" : rleg[0].crn,
                "Flight Return Number Of Stops": oneway ? "" : rleg.length - 1 == 0 ? "non-stop" : rleg.length - 1 + "-stop",
                "Flight Return Airline Number": oneway ? "" : Number(rleg[0].fnum),
                "Flight Return Arrival Time": oneway ? "" : new Date(this.formatDate(rleg[rleg.length - 1].adt) + " " + rleg[rleg.length - 1].ati),
                "Flight Return Departure Time": oneway ? "" : new Date(this.getDate('ret') + " " + rleg[0].dti),
                "Flight Return Seat Left": oneway ? "" : rleg[0].seat,
                "Flight Return Refundable": oneway ? "" : (intl ? d.data.Refundable : d.state.returnFlightDetails.Refundable) ? "Y" : "N",
                "Flight Return Miles offered": d.data.Ttype !== 'One Way' && cat == 'Earn' ? intl ? d.state.adultmiles : d.state.returnFlexiDetail ? d.state.returnFlexiDetail.JPMiles : d.state.JPMilesReturn : "",
                "Flight Return Fare Type": oneway ? "" : d.state.returnFlexiDetail ?.flexiBenefitDetails ?.CATEGORY_NAME || "Saver",
                "Flight Onword Inclusions": "Cabin:7kg,check-in:" + oleg[0].aircraftBaggageWeight + oleg[0].aircraftBaggageUnit,
                "Flight Return Inclusion": oneway ? "" : "Cabin:7kg,check-in:" + rleg[0].aircraftBaggageWeight + rleg[0].aircraftBaggageUnit,
                "Filter Used": is_filter_sort_present ? this.formatCTFilters(window.clevertapData["Others"]["filtersort"]["filters"]) : "",
                "Sort Used": is_filter_sort_present ? this.formatCTSort(window.clevertapData["Others"]["filtersort"]["sort"]["onwardSort"], window.clevertapData["Others"]["filtersort"]["sort"]["onwardSortType"]) : "",
                "Flight Return Fliter Used": is_filter_sort_present ? round ? this.formatCTFiltersReturn(window.clevertapData["Others"]["filtersort"]["filters"]) : "" : "",
                "Flight Return Sort Used": is_filter_sort_present ? round ? this.formatCTSort(window.clevertapData["Others"]["filtersort"]["sort"]["returnSort"], window.clevertapData["Others"]["filtersort"]["sort"]["retrunSortType"]) : "" : "",
            };
            if(!rdm) window["clevertapData"]["Add to Cart"]["Miles Earned"] = (window["clevertapData"]["Add to Cart"]["Flight Onward Miles offered"] + (round ? window["clevertapData"]["Add to Cart"]["Flight Return Miles offered"]||0 : 0))
                * (oneway || intl ? d.data.traveller : 1);
            this.pushEvent("Add to Cart");
        } catch (e) {
            //logger("ct err:", e)
            this.callErrEvt("Add to Cart", e);
        }
    },
    payNow: function (d) {
        //logger("cout:", d);
        try {
            let bkg = d.state.bookingDetails, round = bkg.Return_Details.length > 0, rdm = window.clevertapData["Global"]['Category'] == "Redeem";
            let noOfAdults = d.state.travelerDetails.adult_details.length;
            let noOfChilds = d.state.travelerDetails.child_details ? d.state.travelerDetails.child_details.length : 0;
            let noOfInfants = d.state.travelerDetails.infant_details ? d.state.travelerDetails.infant_details.length : 0;

            window["clevertapData"]["Check Out"] = {
                'Action': 'Pay Now',
                // 'Destination URL':'<destination url / deep link>', 
                'Amount': window.clevertapData["Others"]["totalPayableAmount"] ? window.clevertapData["Others"]["totalPayableAmount"] : Math.floor(Number(d.state.fareDetail.convenienceFee.value.substring(1))),
                'Miles Earned': (d.state.isEarn && d.state.isSwmSelected) ? "" : window.clevertapData["Others"]["totalEarnedMiles"] || parseInt(d.state.fareDetail.earnings.earningsTotal.value),
                'Miles Redeemed': rdm ? parseInt(d.state.fareDetail.interMilesRedeemed.value) : (d.state.isEarn && d.state.isSwmSelected) ? parseInt(d.state?.swmdata?.m) : "",
                'Miles Purchase': rdm ? parseInt(d.state.fareDetail.interMilesRedeemed.tobePurchased.value) : "",
                'Miles Purchase Amount': rdm ? parseInt(d.state.fareDetail.interMilesPurchased.value.match(/\d+/)) : "",
                'Convenience fee': Math.floor(Number(d.state.fareDetail.convenienceFee.value.substring(1))),
                'Flight Origin Code': bkg.Onward_Details[0].ORIGIN_CODE,
                'Flight Destination Code': bkg.Onward_Details[bkg.Onward_Details.length - 1].DESTINATION_CODE,
                'Origin Destination': bkg.Onward_Details[0].ORIGIN_CODE + '-' + bkg.Onward_Details[bkg.Onward_Details.length - 1].DESTINATION_CODE,
                'Flight Origin Description': bkg.Onward_Details[0].ORIGIN,
                'Flight Destination Description': bkg.Onward_Details[bkg.Onward_Details.length - 1].DESTINATION,
                'Flight Origin Airport': bkg.Onward_Details[0].ORIGIN_AIRPORT,
                'Flight Destination Airport': bkg.Onward_Details[bkg.Onward_Details.length - 1].DESTINATION_AIRPORT,
                'Flight Onward Date': new Date(bkg.Onward_Details[0].DEST_DATE + " " + bkg.Onward_Details[0].DEPARTURE_TIME),
                'Flight Return Date': round ? new Date(bkg.Return_Details[0].DEST_DATE + " " + bkg.Return_Details[0].DEPARTURE_TIME) : "",
                'Flight Booking Class': bkg.Onward_Details[0].CABIN_CLASS,
                'Flight Journey Type': round ? "Return" : "One Way",
                'Flight Type': d.state.isInternational ? "International" : "Domestic",
                'Flight Travellers Adult': noOfAdults,
                'Flight Travellers Child': noOfChilds,
                'Flight Travellers Infant': noOfInfants,
                'Total Travellers': parseInt(noOfAdults) + parseInt(noOfChilds) + parseInt(noOfInfants),
                'Flight Onward Airline Partner': bkg.Onward_Details[0].AIRLINE_NAME,
                'Flight Onward Number Of Stops': bkg.Onward_Details.length == 1 ? "non-stop" : bkg.Onward_Details.length - 1 + "-stop",
                'Flight Onward Airline Number': Number(bkg.Onward_Details[0].AIRLINE_NUMBER),
                'Flight Onward Arrival Time': new Date(bkg.Onward_Details[bkg.Onward_Details.length - 1].ARRI_DATE + " " + bkg.Onward_Details[bkg.Onward_Details.length - 1].ARRIVAL_TIME),
                'Flight Onward Departure Time': new Date(bkg.Onward_Details[0].DEST_DATE + " " + bkg.Onward_Details[0].DEPARTURE_TIME),
                // 'Flight Onward Seat Left':'< number of seat left for the flight selected >',
                'Flight Onward Refundable': bkg.Onward_Details[0].ISREFUNDABLE ? "Y" : "N",
                'Flight Onward Miles offered': "",
                'Flight Onward Fare Type': bkg.Onward_Details[0].CATEGORY_NAME,
                // 'Flight Onward Miles Redeemed':'< No of miles user will refeem for onward journey>', 
                // 'Flight Return Miles Redeemed':'< No of miles user will refeem for return journey>', 
                'Flight Return Airline Partner': round ? bkg.Return_Details[0].AIRLINE_NAME : "",
                'Flight Return Number Of Stops': round ? bkg.Return_Details.length == 1 ? "non-stop" : bkg.Return_Details.length - 1 + "-stop" : "",
                'Flight Return Airline Number': round ? Number(bkg.Return_Details[0].AIRLINE_NUMBER) : "",
                'Flight Return Arrival Time': round ? new Date(bkg.Return_Details[bkg.Return_Details.length - 1].ARRI_DATE + " " + bkg.Return_Details[bkg.Return_Details.length - 1].ARRIVAL_TIME) : "",
                'Flight Return Departure Time': round ? new Date(bkg.Return_Details[0].DEST_DATE + " " + bkg.Return_Details[0].DEPARTURE_TIME) : "",
                // 'Flight Retrun Seat Left':'< number of seat left for the flight selected >', 
                'Flight Return Refundable': round ? bkg.Return_Details[0].ISREFUNDABLE ? "Y" : "N" : "",
                'Flight Return Miles offered': "",
                'Flight Return Fare Type': round ? bkg.Return_Details[0].CATEGORY_NAME : "",
                'Flight Onword Inclusions': "Cabin:7kg,Check-In:" + bkg.baggage_info[0].BAGGAGE_INFO + bkg.baggage_info[0].BAGGAGE_UNIT + ",meals included:" + (bkg.Onward_Details[0].MEAL_INCLUDE ? "Y" : "N"),
                'Flight Return Inclusion': round ? ("Cabin:7kg,Check-In:" + bkg.baggage_info[bkg.baggage_info.length - 1].BAGGAGE_INFO + bkg.baggage_info[bkg.baggage_info.length - 1].BAGGAGE_UNIT + ",meals included:" + (bkg.Return_Details[0].MEAL_INCLUDE ? "Y" : "N")) : "",
                'Flight Frequent Flyer Number': -1 != d.state.travellerInfo.findIndex(p => { return -1 != p.FFPDetails.findIndex(f => { return f.ffpNumber != "" }) }) ? "Y" : "N",
                'GST Number': d.state.gst ? "Y" : "N",
                'Flight Ancillaries Combo': d.state.isComboSelected ? "Y" : "N",
                'Flight Ancillaries Meal': Object.keys(d.state.mealsMiles).find(a => { return d.state.mealsMiles[a] }) ? "Y" : "N",
                'Flight Ancillaries Seat': Object.keys(d.state.seats).find(a => { return d.state.seats[a].length }) ? "Y" : "N",
                'Flight Ancillaries Baggage': Object.keys(d.state.baggageMiles).find(a => { return d.state.baggageMiles[a] }) ? "Y" : "N",//d.state.isBaggagePresent
                'Flight Ancillaries Loung': Object.keys(d.state.checkedLoungeAccess).find(a => { return d.state.checkedLoungeAccess[a] }) ? "Y" : "N",//d.state.isLoungePresent,
                'Apply Promo Code': rdm ? "" : d.state.promocode ? "Y" : "N",
                'Offer Selected': rdm ? "" : parseInt(window.clevertapData["Others"]["discountPrice"].replace(/\.00|\D/g, "")) > 0 ? "Y" : "N",
                'Cost of Ticket': parseInt(d.state.CostofTicket),
                'Offer Name': d.state.OfferName,
                'Offer ID': d.state.OfferID,
                'Promo Code': d.state.PromoCode,
                'Payment Type': d.state.isEarn ? (d.state.isSwmSelected ? "Miles_Cash" : "Accrual") : (d.state.isMilesPurchased ? "Shortfall_Miles" : "Redemption"),
                'FC Selected': d.state.isFreeCancellationSelected[Object.keys(d.state.isFreeCancellationSelected)[0]] || d.state.isFreeCancellationSelected[Object.keys(d.state.isFreeCancellationSelected)[1]] ? 'Y' : 'N'
            };
            // logger(JSON.stringify(window["clevertapData"]["Check Out"], null, 2));
            this.pushEvent("Check Out");
        } catch (e) {
            // logger("ct err:", e)
            this.callErrEvt("Check Out", e);
        }
    },
    charged: function (d) {
        //logger("charged:", d);
        let bkg = d.bookingSummary, evt_name = [0, 1, 2].includes(bkg.bookingStatus) ? "Charged" : "Charged Fail", round = bkg.segment.length > 1;
        try {
            let rdm = window.clevertapData["Global"]['Category'] == "Redeem";
            let formatNum = (n) => { try{return parseInt(n.replace(/\.00|\D/g, ""))}catch(e){return "";} };
            let ssrSelected = (ssr) => {
                return -1 != bkg.segment.findIndex(s => {
                    return -1 != s.legs.findIndex(l => {
                        return -1 != l.travellers.findIndex(t => {
                            return -1 != t.ancillaries.findIndex(a => {
                                return a.text == ssr && a.value.indexOf("Not Opted") == -1
                            })
                        })
                    })
                })
            };

            let noOfAdults = bkg.segment[0].legs[0].travellers.filter(t => { return t.passengerType == "Adult" }).length;
            let noOfChilds = bkg.segment[0].legs[0].travellers.filter(t => { return t.passengerType == "Child" }).length;
            let noOfInfants = bkg.segment[0].legs[0].travellers.filter(t => { return t.passengerType == "Infant" }).length;

            let departureDate = new Date(bkg.segment[0].legs[0].departureDate);
            let newdate = new Date(departureDate);
            newdate.setDate(newdate.getDate() + 1);

            let bookingStatus = "";
            if (bkg.bookingStatus == 1 || bkg.bookingStatus == 0) {
                bookingStatus = "Booking Pending";
            }
            else if (bkg.bookingStatus == 2) {
                bookingStatus = "Booking Confirmed";
            }
            else if (bkg.bookingStatus == 4) {
                bookingStatus = "Booking Fail";
            }

            window["clevertapData"][evt_name] = {
                'Action': bookingStatus,
                'Charged ID': bkg.bookingId,
                'IM Number': d.IMNumber,
                'Amount': formatNum(d.fareSummary.totalAmt.value),
                'Miles Earned': formatNum(d.miles?.totalEarning?.value),
                'Miles Redeemed': rdm ? formatNum(d.fareSummary?.totalMilesRedeemed?.value) : d.fareSummary?.swmOfferused ? parseInt(d.fareSummary.swmOfferused.value.split(" ")[0]) : '',
                'Miles Purchase': rdm ? formatNum(d.fareSummary?.milesPurchased?.value) : '',
                'Miles Purchase Amount': rdm ? formatNum(d.fareSummary?.costOfAddMiles?.value) : '',
                'Convenience fee': formatNum(d.fareSummary.convenienceFee.value),
                'Flight Origin Code': bkg.segment[0].legs[0].origin,
                'Origin Destination': bkg.segment[0].legs[0].origin + '-' + bkg.segment[0].legs[bkg.segment[0].legs.length - 1].destination,
                'Flight Destination Code': bkg.segment[0].legs[bkg.segment[0].legs.length - 1].destination,
                'Flight Origin Description': bkg.segment[0].legs[0].originCity,
                'Flight Destination Description': bkg.segment[0].legs[bkg.segment[0].legs.length - 1].destinationCity,
                'Flight Origin Airport': bkg.segment[0].legs[0].departureTerminal,
                'Flight Destination Airport': bkg.segment[0].legs[bkg.segment[0].legs.length - 1].arrivalTerminal,
                'Flight Return Date': round ? new Date(bkg.segment[1].legs[0].departureDate + " " + bkg.segment[1].legs[0].departureTime) : "",
                'Flight Onward Date': new Date(bkg.segment[0].legs[0].departureDate + " " + bkg.segment[0].legs[0].departureTime),
                'Flight Booking Class': bkg.segment[0].legs[0].class,
                'Flight Journey Type': round ? "Return" : "One Way",
                'Flight Type': bkg.travelType == "INTERNATIONAL" ? "International" : "Domestic",
                'Flight Travellers Adult': noOfAdults,
                'Flight Travellers Child': noOfChilds,
                'Flight Travellers Infant': noOfInfants,
                'Total Travellers': parseInt(noOfAdults) + parseInt(noOfChilds) + parseInt(noOfInfants),
                'Flight Onward Airline Partner': bkg.segment[0].legs[0].airlineName,
                'Flight Onward Number Of Stops': bkg.segment[0].legs.length == 1 ? "non-stop" : bkg.segment[0].legs.length - 1 + "-stop",
                'Flight Onward Airline Number': parseInt(bkg.segment[0].legs[0].flightNumber),
                'Flight Onward Arrival Time': new Date(bkg.segment[0].legs[bkg.segment[0].legs.length - 1].arrivalDate + " " + bkg.segment[0].legs[bkg.segment[0].legs.length - 1].arrivalTime),
                'Flight Onward Departure Time': new Date(bkg.segment[0].legs[0].departureDate + " " + bkg.segment[0].legs[0].departureTime),
                // 'Flight Onward Seat Left': '',
                'Flight Onward Refundable': bkg.segment[0].refundable ? "Y" : "N",
                // 'Flight Onward Miles offered': '',
                'Flight Onward Fare Type': bkg.segment[0].legs[0].flexiFareCategory || "Saver",
                'Flight Return Airline Partner': round ? bkg.segment[1].legs[0].airlineName : "",
                'Flight Return Number Of Stops': round ? bkg.segment[1].legs.length == 1 ? "non-stop" : bkg.segment[1].legs.length - 1 + "-stop" : "",
                'Flight Return Airline Number': round ? parseInt(bkg.segment[1].legs[0].flightNumber) : "",
                'Flight Return Arrival Time': round ? new Date(bkg.segment[1].legs[bkg.segment[1].legs.length - 1].arrivalDate + " " + bkg.segment[1].legs[bkg.segment[1].legs.length - 1].arrivalTime) : "",
                'Flight Return Departure Time': round ? new Date(bkg.segment[1].legs[0].departureDate + " " + bkg.segment[1].legs[0].departureTime) : "",
                // 'Flight Retrun Seat Left': '',
                'Flight Return Refundable': round ? bkg.segment[1].refundable ? "Y" : "N" : "",
                // 'Flight Return Miles offered': '',
                'Flight Return Fare Type': round ? bkg.segment[1].legs[0].flexiFareCategory || "Saver" : "",
                'Flight Onword Inclusions': "Cabin:7kg,Check-In:" + bkg.segment[0].legs[0].checkInBaggage,
                'Flight Return Inclusion': round ? "Cabin:7kg,Check-In:" + bkg.segment[1].legs[0].checkInBaggage : "",
                'Flight Frequent Flyer Number': d.ffpDetails && d.ffpDetails.findIndex(f => { return f.FREQUENT_FLIER_NO != "" && f.FREQUENT_FLIER_NO != "NA" }) != -1 ? "Y" : "N",
                'GST Number': bkg.gstNo ? "Y" : "N",////
                'Flight Ancillaries Combo': d.comboOffer?.header ? "Y" : "N",
                'Flight Ancillaries Meal': ssrSelected("Meal") ? "Y" : "N",
                'Flight Ancillaries Seat': ssrSelected("Seat") ? "Y" : "N",
                'Flight Ancillaries Baggage': ssrSelected("Excess Baggage") ? "Y" : "N",
                'Flight Ancillaries Loung': ssrSelected("Lounge Access") ? "Y" : "N",
                'Apply Promo Code': d.fareSummary.promocodeDiscount && d.fareSummary.promocodeDiscount.text.indexOf("Promocode") != -1 ? "Y" : "N",
                'Offer Selected': d.fareSummary.cobrandDiscount || d.fareSummary.cobrandOfferDiscount || (d.fareSummary.promocodeDiscount && d.fareSummary.promocodeDiscount.text.indexOf("Instant") != -1) ? "Y" : "N",
                'Error Message': bkg.bookingStatus == 4 ? 'Sorry, we were unable to to confirm your booking.' : '',
                'Cost of Ticket': d.fareSummary.costOfTicket.toString(),
                'Offer Name': !rdm? (d.fareSummary.cleverTap.length > 0 && d.fareSummary.cleverTap.OFFER_TYPE != "GENERIC" ? d.fareSummary.cleverTap.offers.OFFER_NAME : '') : '',
                'Offer ID': "",
                'Promo Code': !rdm? (d.fareSummary.cleverTap.length > 0 && d.fareSummary.cleverTap.OFFER_TYPE == "GENERIC" ? d.fareSummary.cleverTap.offers.PROMO_CODE : '') : '',
                'Payment Type': !rdm ? (d.fareSummary.isSWMOfferUsed ? "Miles_Cash" : "Accrual") : (parseInt(formatNum(d.fareSummary?.costOfAddMiles?.value)) > 0 ? "Shortfall_Miles" : "Redemption"),
                "RemarketingCheckInDate": dateFormat(departureDate, "yyyy-mm-dd"),
                "RemarketingCheckOutDate": dateFormat(newdate, "yyyy-mm-dd"),
                "Flight PNR Number": bkg.bookingStatus == 2 ? bkg.segment[0].legs[0].pnr : "",
                "Return Flight PNR Number": bkg.bookingStatus == 2 && bkg.tripType.toLowerCase() == "return" ? bkg.segment[1].legs[0].pnr : ""
            };
            this.pushEvent(evt_name);
        } catch (e) {
            // logger("ct err:", e)
            this.callErrEvt(evt_name, "Charged Event Exception: " + bkg.bookingId);
        }
    },
}